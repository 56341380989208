import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import './GridInfo.scss'
import classNames from 'classnames'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param { "h2" | "h3" |"h4" } props.params.Heading
 *
 * @param {Object} props.rendering
 * @param {string} props.rendering.uid
 */

const aw_common_grid_info_3col = (props) => {
  const id = props.rendering?.uid

  const classes = classNames('grid-info grid-info-3 container component', {
    'caption_h2': props.params.Heading === 'h2',
    'caption_h3': props.params.Heading === 'h3',
    'caption_h4': props.params.Heading === 'h4',
  })

  return (
    <div className={classes} id={id}>
      <div className="row" data-navigation-label={props.params.InPageNavigationLabel}>
        <div className="col-12 col-md-4 mb-8 mb-md-5">
          <Placeholder name="ph-aw-common-grid-info-col1" rendering={props.rendering} />
        </div>
        <div className="col-12 col-md-4 mb-8 mb-md-5">
          <Placeholder name="ph-aw-common-grid-info-col2" rendering={props.rendering} />
        </div>
        <div className="col-12 col-md-4 mb-8 mb-md-5">
          <Placeholder name="ph-aw-common-grid-info-col3" rendering={props.rendering} />
        </div>
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_grid_info_3col))
