import React, { useState, createRef } from 'react'
import { Icon } from '../../common/Icon'
import { Button } from '../../common/Button'

import './Navigation.scss'
import { LevelMenu } from './LevelMenu'
import classNames from 'classnames'
import { Link } from '@sitecore-jss/sitecore-jss-react'

/**
 * @typedef {Object} Item
 * @property {Array<Item>} childs
 * @property {string} displayName
 * @property {Object} fields
 * @property {string} id
 * @property {string} name
 * @property {string} template
 *
 * @param {Object} props
 * @param {Array<Item>} props.items
 * @param {"mobile" | "desktop"} props.type
 * @param {function(string): string} props.t
 */
export const Menu = ({ items, type, t }) => {
  const [activeItem, setActiveItem] = useState(null)
  const [activeTopLink, setActiveTopLink] = useState(null)

  const menuItems = items.filter(
    (item) => item.template === 'aw_navigation_main_datasource_toplevel_item',
  )

  const topLevelLiRefs = menuItems.map(() => createRef())

  return (
    <nav aria-label={t('aw_navigation_primary')}>
      {(activeItem === null || type !== 'mobile') && (
        <ul className="navbar-nav nav navigation__menu">
          {menuItems.map((item, idx) => (
            <li
              ref={topLevelLiRefs[idx]}
              className={classNames('nav-item', {
                'nav-item--active': item === activeItem,
                'dropdown': item.items?.length,
              })}
              key={idx}
              onMouseEnter={() => {
                if (type !== 'mobile') setActiveItem(item)
              }}
              onMouseLeave={() => {
                if (type !== 'mobile') setActiveItem(null)
              }}
            >
              <Link
                field={item.fields?.Link}
                editable={false}
                onClick={(e) => {
                  if (type === 'mobile' && item.items?.length) {
                    e.preventDefault()
                  }

                  setActiveItem(item)
                  setActiveTopLink(item)
                }}
                onFocus={() => {
                  if (type !== 'mobile') setActiveItem(item)
                }}
                onKeyDown={({ key }) => {
                  if (key === 'Tab') {
                    setActiveItem(null)
                  }
                }}
                className="nav-link"
              >
                <span>{item.fields?.Title?.value}</span>
                {item.items?.length > 0 && (
                  <Icon name="arrow" rotate={type === 'mobile' ? 0 : 90} size={20} />
                )}
              </Link>
              {item.items?.length > 0 && (
                <LevelMenu
                  parentLiRef={topLevelLiRefs[idx]}
                  isVisible={activeItem === item ? true : false}
                  items={item.items}
                  type={type}
                  t={t}
                  button={items[items.length - 1]}
                  onMouseLeave={() => setActiveItem(null)}
                  onMouseEnter={() => setActiveItem(activeItem)}
                />
              )}
            </li>
          ))}
          <li className="navigation__menu__button">
            <Button field={items[items.length - 1].fields?.Link} variant="primary" />
          </li>
        </ul>
      )}
      {type === 'mobile' && activeItem?.items?.length > 0 && (
        <LevelMenu
          isVisible={activeItem ? true : false}
          items={activeItem.items}
          activeTopLink={activeTopLink}
          activeItem={activeItem}
          type={type}
          t={t}
          button={items[items.length - 1]}
          onMouseLeave={() => setActiveItem(null)}
          onMouseEnter={() => setActiveItem(activeItem)}
          setActiveItem={setActiveItem}
        />
      )}
    </nav>
  )
}
