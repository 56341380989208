import React from 'react'
import { Link, Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Button } from '../../common/Button'
import './Links.scss'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Array} props.fields.items
 */

const aw_navigation_links = (props) => {
  const id = props.rendering?.uid

  return (
    <div
      className="links container component"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
    >
      <div className="row">
        <div className="col-12 col-lg-3 links-heading">
          {props.fields?.Caption && <Text field={props.fields?.Caption} tag="h3" className="h2" />}
        </div>
        {props.fields?.items &&
          props.fields.items.map((item, idx) => (
            <div key={idx} className="col-12 col-md-6 col-lg-3">
              {item.items && item.items.length > 0 && (
                <ul>
                  {item.items.map((childItem) => (
                    <li key={childItem.id}>
                      <Link field={childItem.fields?.Link} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
      </div>
      {props.fields?.CtaButton && props.fields?.CtaButton.value?.href && (
        <Button field={props.fields?.CtaButton} variant="secondary" />
      )}
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_navigation_links))
