import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @property {boolean} props.rendering
 */

const aw_common_events_normal = (props) => {
  const id = props.rendering?.uid

  return (
    <div
      className="events-list container component"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
    >
      <h2 className="events-list__heading">{props.t('aw_common_events_label_top')}</h2>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
        <Placeholder
          className="col-12 col-md-6 col-lg-3"
          name="ph-aw-common-events-insets"
          rendering={props.rendering}
        />
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_events_normal))
