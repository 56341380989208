import { Link } from '@sitecore-jss/sitecore-jss-react'
import React, { useRef, useEffect } from 'react'
import { Button } from '../../common/Button'
import { Icon } from '../../common/Icon'

import { isClient } from '../../util'

import './Navigation.scss'
import classNames from 'classnames'

/**
 * @typedef {Object} Item
 * @property {Array<Item>} childs
 * @property {string} displayName
 * @property {Object} fields
 * @property {string} id
 * @property {string} name
 * @property {string} template
 *
 * @param {Object} props
 * @param {Array<Item>} props.items
 * @param {function(): void} props.onMouseLeave
 * @param {function(): void} props.onMouseEnter
 * @param {function(string): string} props.t
 * @param {"mobile" | "desktop"} props.type
 * @param {Item} button
 */
export const LevelMenu = ({
  isVisible,
  items,
  activeTopLink,
  activeItem,
  onMouseLeave,
  onMouseEnter,
  type,
  button,
  t,
  parentLiRef,
  setActiveItem,
}) => {
  const parentDivElement = useRef()

  useEffect(() => {
    if (!isVisible || !isClient()) return
    let indexOfAnchor = null
    const dropdownLinks = Array.from(parentDivElement.current.querySelectorAll('a'))
    const parrentLink = type !== 'mobile' ? parentLiRef.current.querySelector('a') : null

    const handleKeyDown = (e) => {
      switch (e.key) {
        default: {
          return
        }
        case 'ArrowDown': {
          e.preventDefault()
          if (indexOfAnchor === dropdownLinks.length - 1 || indexOfAnchor === null) {
            indexOfAnchor = 0
          } else {
            indexOfAnchor++
          }
          dropdownLinks[indexOfAnchor].focus()
          return
        }

        case 'ArrowUp': {
          e.preventDefault()
          if (indexOfAnchor === 0 || indexOfAnchor === null) {
            indexOfAnchor = dropdownLinks.length - 1
          } else {
            indexOfAnchor--
          }
          dropdownLinks[indexOfAnchor].focus()
          return
        }
        case 'Home': {
          e.preventDefault()
          indexOfAnchor = 0
          dropdownLinks[indexOfAnchor].focus()
          return
        }
        case 'End': {
          e.preventDefault()
          indexOfAnchor = dropdownLinks.length - 1
          dropdownLinks[indexOfAnchor].focus()
          return
        }
        case 'Escape': {
          if (parrentLink === null) return
          indexOfAnchor = null
          parrentLink.focus()
          return
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  const toggleByMouseOver = (enter) => {
    if (type === 'mobile') return

    if (enter) {
      onMouseEnter()
    } else {
      onMouseLeave()
    }
  }

  const backToMainMenu = () => {
    const isSecondLevelMenu = activeTopLink === activeItem

    if (type === 'mobile') {
      isSecondLevelMenu ? onMouseLeave() : setActiveItem(activeTopLink)
    }
  }

  return (
    <div
      className={classNames('navigation__submenu', {
        'navigation__submenu_active': isVisible,
      })}
      onMouseLeave={() => {
        toggleByMouseOver(false)
      }}
      onMouseEnter={() => {
        toggleByMouseOver(true)
      }}
    >
      <div className="container">
        {type === 'mobile' && (
          <button className="navigation__submenu__back-button" onClick={backToMainMenu}>
            <Icon name="arrow" rotate={180} size={20} />
            <span>{t('aw_navigation_main_back')}</span>
          </button>
        )}
        <div
          className={classNames('navigation__submenu__lists', {
            'has_submenu': items[0].items.length,
          })}
          ref={parentDivElement}
        >
          {items.map((item, idx) => {
            return (
              <ul key={idx}>
                <li>
                  <Link
                    field={item.fields?.Link}
                    editable={false}
                    tabIndex={type === 'mobile' ? 0 : -1}
                    onClick={(e) => {
                      if (type === 'mobile' && item.items?.length) {
                        e.preventDefault()
                      }

                      setActiveItem(item)
                    }}
                  >
                    {item.fields?.Title?.value}
                    {type === 'mobile' && item.items?.length > 0 && (
                      <Icon name="arrow" rotate={type === 'mobile' ? 0 : 90} size={20} />
                    )}
                  </Link>
                </li>
                {type !== 'mobile' &&
                  item.items.map((childItem, childIdx) => {
                    return (
                      <li key={childIdx}>
                        <Link field={childItem.fields?.Link} tabIndex={type === 'mobile' ? 0 : -1}>
                          {childItem.fields?.Title?.value}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            )
          })}
        </div>
        {type === 'mobile' && (
          <ul>
            <li className="navigation__menu__button">
              <Button
                field={{
                  value: {
                    text: button?.displayName,
                  },
                }}
                variant="primary"
              />
            </li>
          </ul>
        )}
      </div>
    </div>
  )
}
