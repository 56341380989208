import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { formatDate } from '../../util'

import { Icon } from '../../common/Icon'
import './CareerPosition.scss'

export default function CareerPosition({ company, displayName, posted, project, unit, url, t }) {
  return (
    <div className="career-positions-item">
      <h3 className="career-positions-item__name h2">{displayName}</h3>
      <div className="row">
        <div className="col col-12 col-lg-4 mb-3 mb-lg-0">
          {company && company.value && (
            <>
              <p className="career-positions-item__title h4">
                {t('aw_common_career_positions_company')}
              </p>
              <Text className="career-positions-item__title body" field={company} tag="p" />
            </>
          )}
        </div>
        <div className="col col-12 col-lg-4 mb-3 mb-lg-0">
          {unit && unit.value && (
            <>
              <p className="career-positions-item__title h4">
                {t('aw_common_career_positions_unit')}
              </p>
              <Text className="career-positions-item__title body" field={unit} tag="p" />
            </>
          )}
        </div>
        <div className="col col-12 col-lg-4 mb-3 mb-lg-0">
          {project && project.value && (
            <>
              <p className="career-positions-item__title h4" field={project}>
                {t('aw_common_career_positions_project')}
              </p>
              <Text className="career-positions-item__title body" field={project} tag="p" />
            </>
          )}
        </div>
      </div>
      <hr />
      <div className="row align-items-center">
        <div className="col col-12 col-lg-6 mb-3 mb-lg-0 career-positions-item__learn-more">
          {url && (
            <a
              className="btn btn--tertiary"
              href={url}
              aria-label={`${t('aw_common_career_positions_learn_more')} 
              ${t('aw_common_about')} ${displayName}')}`}
            >
              {t('aw_common_career_positions_learn_more')}
              <Icon name="arrow" size={18} />
            </a>
          )}
        </div>
        <div className="col col-12 col-lg-6 mb-3 mb-lg-0 career-positions-item__posted">
          {posted && posted.value && (
            <div className="row">
              <p className="caption">
                {t('aw_common_career_positions_posted')} {formatDate(posted.value)}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
