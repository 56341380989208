import React from 'react'
import { Text, Link } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Icon } from '../../common/Icon'
import '../aw_common_contact_us/ContactUs.scss'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {string} props.fields.Language
 * @param {string} props.fields.Name
 * @param {string} props.fields.Email
 * @param {string} props.fields.Phone
 * @property {Object} props.sitecoreContext
 * @property {boolean} props.sitecoreContext.pageEditing
 */

const aw_common_contact_us_inset = (props) => {
  const isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing

  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="contact-us-item">
        <div className="contact-us-item-lang">
          <Icon name="translate" size={24} />
          <Text className="h3" field={props.fields?.Language} tag="h4" />
        </div>
        <div className="contact-us-item-contact-data">
          <Text
            className="h4 contact-us-item-contact-data__name"
            field={props.fields?.Name}
            tag="p"
          />
          <div className="contact-us-item-contact-data__row">
            <span className="fw-bold me-4" aria-hidden="true">
              {props.t('aw_navigation_footer_email_short_label')}:
            </span>
            <span className="visually-hidden">{props.t('aw_navigation_footer_email_label')}</span>
            <Link className="body" field={props.fields?.Email} tag="p" />
          </div>
          <div className="contact-us-item-contact-data__row">
            <span className="fw-bold me-4" aria-hidden="true">
              {props.t('aw_navigation_footer_phone_short_label')}:
            </span>
            <span className="visually-hidden">{props.t('aw_navigation_footer_phone_label')}</span>
            <a className="body" href={`tel:${props.fields?.Phone.value}`}>
              {isEditing ? <Text field={props.fields?.Phone} /> : props.fields?.Phone.value}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_contact_us_inset))
