import React from 'react'
import { Link, Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import Media from 'react-media'
import { Icon } from '../../common/Icon'
import { FooterCollapse } from './FooterCollapse'

import './Footer.scss'

/**
 * @typedef {Object} JSSTextField
 * @property {string} value
 *
 * @typedef {Object} JSSLinkField
 * @property {Object} value
 * @property {string} value.href
 * @property {string} value.text
 * @property {string} value.url
 * @property {string} value.target
 * @property {string} value.title
 *
 * @typedef {Object} Props
 * @property {Object} sitecoreContext
 * @property {boolean} sitecoreContext.pageEditing
 * @property {Fields} fields
 * @property {function(string): string} t
 *
 * @typedef {Object} Fields
 * @property {RootFields[]} items
 *
 * @typedef {Object} RootFields
 * @property {Object} fields
 * @property {JSSTextField} fields.Logo
 * @property {JSSTextField} fields.Address
 * @property {JSSTextField} fields.Phone
 * @property {JSSLinkField} fields.Email
 * @property {JSSTextField} fields.Caption
 * @property {Child[]} roots
 *
 * @typedef {Object} Child
 * @property {string} id
 * @property {string} name
 * @property {string} displayName
 * @property {'aw_navigation_footer_datasource_items_container' | 'aw_navigation_footer_datasource_toplevel_item' | 'aw_navigation_footer_datasource_offices_container' | 'aw_navigation_footer_datasource_offices_item' | 'aw_navigation_footer_datasource_social_container' | 'aw_navigation_footer_datasource_social_item' | 'aw_navigation_footer_datasource_subfooter_container' | 'aw_navigation_footer_datasource_subfooter_item'} template
 * @property {Array} items
 *
 * @typedef {Object} SocialItemFields
 * @property {Object} fields
 * @property {JSSTextField} fields.Icon
 * @property {JSSLinkField} fields.Link
 *
 * @typedef {SocialItemFields & Child} SocialItem
 *
 * @typedef {Object} OfficeFields
 * @property {Object} fields
 * @property {JSSTextField} fields.Address
 * @property {JSSTextField} fields.Location
 *
 * @typedef {OfficeFields & Child} Office
 *
 * @typedef {Object} SubFooterLinkFields
 * @property {Object} fields
 * @property {JSSLinkField} fields.Link
 *
 * @typedef {SubFooterLinkFields & Child} SubFooterLink
 *
 * @typedef {Object} TopFooterLinkFields
 * @property {Object} fields
 * @property {JSSLinkField} fields.Link
 *
 * @typedef {TopFooterLinkFields & Child} TopFooterLink
 *
 * @param {Props} props
 */
const aw_navigation_footer = (props) => {
  const { sitecoreContext, t } = props
  const isEditing = sitecoreContext && sitecoreContext.pageEditing
  const { fields } = props
  const roots = props.fields.items

  /**
   * @type {TopFooterLink[]}
   */
  const topFooterLinks =
    roots.find((child) => child.template === 'aw_navigation_footer_datasource_items_container')
      ?.items ?? []

  /**
   * @type {SocialItem[]}
   */
  const socialItems =
    roots.find((child) => child.template === 'aw_navigation_footer_datasource_social_container')
      ?.items ?? []

  /**
   * @type {Office[]}
   */
  const offices =
    roots.find((child) => child.template === 'aw_navigation_footer_datasource_offices_container')
      ?.items ?? []

  /**
   * @type {SubFooterLink[]}
   */
  const subFooterLinks =
    roots.find((child) => child.template === 'aw_navigation_footer_datasource_subfooter_container')
      ?.items ?? []

  const id = props.rendering?.uid

  return (
    <footer className="footer" id={id}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="mb-10 mb-lg-15">
              <Icon name={fields.Logo.value} width={130} height={20} className="footer__logo" />
            </div>
            <div>
              <span className="fw-bold me-4" aria-hidden="true">
                {t('aw_navigation_footer_email_short_label')}:
              </span>
              <span className="visually-hidden">{t('aw_navigation_footer_email_label')}</span>
              <Link field={fields.Email} />
            </div>
            <div className="mb-16">
              <span className="fw-bold me-4" aria-hidden="true">
                {t('aw_navigation_footer_phone_short_label')}:
              </span>
              <span className="visually-hidden">{t('aw_navigation_footer_phone_label')}</span>
              <a href={`tel:${fields.Phone.value}`}>
                {isEditing ? <Text field={fields.Phone} /> : fields.Phone.value}
              </a>
            </div>
            <RichText field={fields.Address} className="footer__address mb-10" />
          </div>

          {/* top links */}
          <div className="col-12 col-md-6 col-lg-8 mb-8 mb-lg-0">
            {topFooterLinks.length > 0 && (
              <div className="row">
                {topFooterLinks.map(({ fields, items }, idx) => (
                  <div key={idx} className="col-12 col-lg-6 col-xl-4 col-xxl-3 mb-lg-8">
                    <Media query="(max-width: 991px)">
                      {(matches) => {
                        // mobile markup
                        if (matches) {
                          return (
                            <FooterCollapse id={idx} label={fields.Link.value.text}>
                              {items.length > 0 && (
                                <ul className="footer__list-toplinks">
                                  {items.map(({ fields }, idx) => (
                                    <li key={idx} className="footer__list-toplinks__item">
                                      <Link field={fields.Link} editable={false}>
                                        {fields.Title.value}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </FooterCollapse>
                          )
                        }

                        // desktop markup
                        return (
                          <>
                            <h2 className="h4 mb-4 mb-lg-15">
                              <Link field={fields.Link} editable={false}>
                                {fields.Title.value}
                              </Link>
                            </h2>
                            {items.length > 0 && (
                              <ul className="footer__list-toplinks">
                                {items.map(({ fields }, idx) => (
                                  <li key={idx} className="footer__list-toplinks__item">
                                    <Link field={fields.Link} editable={false}>
                                      {fields.Title.value}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </>
                        )
                      }}
                    </Media>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* /top links */}
        </div>

        <Text field={fields.Caption} tag="h2" className="h2 mb-8" />

        {/* offices */}
        <div className="row">
          {offices.length > 0 &&
            offices.map(({ fields }, idx) => (
              <div key={idx} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="footer__office">
                  <Text field={fields.Location} tag="h3" className="h4 mb-8" />
                  <RichText field={fields.Address} tag="div" className="footer__office__address" />
                </div>
              </div>
            ))}
        </div>
        {/* /offices */}

        <div className="row align-items-center">
          {/* social medias */}
          <div className="col-12 col-lg-6 mb-4 mb-lg-0">
            {socialItems.length > 0 && (
              <ul
                aria-label={t('aw_navigation_footer_social_media_label')}
                className="footer__list-social"
              >
                {socialItems.map(({ name, fields }, idx) => (
                  <li key={idx} className="footer__list-social__item">
                    <Link field={fields.Link} aria-label={name}>
                      <Icon name={fields.Icon.value.toLowerCase()} size={24} />
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {/* /social medias */}

          {/* sub links */}
          <div className="col-12 col-lg-6">
            {subFooterLinks.length > 0 && (
              <nav aria-label={t('aw_navigation_footer_navigation_label')}>
                <ul className="footer__list-sublinks">
                  {subFooterLinks.map(({ fields }, idx) => (
                    <li key={idx} className="footer__list-sublinks__item">
                      <Link field={fields.Link} editable={false}>
                        {fields.Title.value}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </div>
          {/* /sub links */}
        </div>
      </div>
    </footer>
  )
}

export default withSitecoreContext()(withTranslation()(aw_navigation_footer))
