import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Button } from '../../common/Button'

import './InsightsWide.scss'
import { hasHref } from '../../util'

/**
 * @param {Object} props
 * @param {boolean} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Object} props.fields.Headline
 * @param {Object} props.fields.CtaButton
 */
const aw_common_insights_wide = (props) => {
  const id = props.rendering?.uid
  return (
    <div
      data-navigation-label={props.params.InPageNavigationLabel}
      className="insights-wide container component"
      id={id}
    >
      {props.fields?.Headline && <Text field={props.fields?.Headline} tag="h2" />}
      <div className="row">
        <Placeholder
          name="ph-aw-common-insights-insets"
          rendering={props.rendering}
          variant="wide"
        />
      </div>
      {hasHref(props.fields?.CtaButton) && (
        <Button field={props.fields?.CtaButton} variant="secondary" />
      )}
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_insights_wide))
