import React from 'react'
import { Image, Text, Link } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Icon } from '../../common/Icon'
import './CareerRecruiter.scss'

/**
 * @param {Object} props
 * @param {Object} params
 * @param {string} params.InPageNavigationLabel
 * @param {string} params.InPageNavigationVisible
 * @property {Object} sitecoreContext
 * @property {boolean} sitecoreContext.pageEditing
 * @param {Object} fields
 * @param {string} fields.Subheadline
 * @param {string} fields.Photo
 * @param {string} fields.Email
 * @param {string} fields.Phone
 * @param {string} fields.RecruiterName
 * @param {string} fields.RecruiterPosition
 */

const aw_common_career_recruiter = (props) => {
  const { fields, params, sitecoreContext } = props
  const isEditing = sitecoreContext && sitecoreContext.pageEditing
  const id = props.rendering?.uid

  return (
    <>
      <div
        className="container component"
        data-navigation-label={params.InPageNavigationLabel}
        id={id}
      >
        <div className="career-recruiter">
          <div className="career-recruiter-info col-12 col-md-6 col-lg-auto">
            {fields?.Subheadline && (
              <Text field={fields.Subheadline} tag="h4" className="mb-6 mb-md-8" />
            )}
            <div className="row align-items-center">
              <div className="col-12 col-md-auto career-recruiter-info__image mb-4 mb-md-0">
                {fields.Photo && (
                  <div className="career-recruiter-info__image-border">
                    <Image field={fields.Photo} loadin="lazy" />
                  </div>
                )}
              </div>
              <div className="col-12 col-md-auto career-recruiter-info__name flex-column">
                {fields.RecruiterName && (
                  <Text field={fields.RecruiterName} tag="h4" className="h2" />
                )}
                {fields.RecruiterPosition && (
                  <Text field={fields.RecruiterPosition} tag="p" className="caption" />
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="career-recruiter-contacts row">
            <div className="col-12 col-md-auto my-2 mx-md-0">
              <Icon name="mail-fill" size={24} />
              <Link className="career-recruiter-contacts__email" field={fields.Email} />
            </div>
            <div className="col-12 col-md-auto my-2 mx-md-0">
              <Icon name="phone-fill" size={24} />
              <a className="career-recruiter-contacts__phone" href={`tel:${fields.Phone.value}`}>
                {isEditing ? <Text field={fields.Phone} /> : fields.Phone.value}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_career_recruiter))
