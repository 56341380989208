import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Button } from '../../common/Button'

import './Gallery.scss'

import { Gallery } from './Gallery'

/**
 * @typedef {Object} GalleryInset
 * @property {Object} fields
 * @property {Object} fields.Image
 *
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Object} props.fields.FurtherImages
 * @param {Object} props.fields.FurtherImages.value
 * @param {Object} props.fileds.Headline
 * @param {Object} props.rendering
 * @param {Object} props.rendering.placeholders
 * @param {Array<GalleryInset>} props.rendering.placeholders.ph-aw-common-gallery-insets
 */

const aw_common_gallery = (props) => {
  const images = props.rendering.placeholders['ph-aw-common-gallery-insets']
  const id = props.rendering?.uid

  return (
    <div data-navigation-label={props.params.InPageNavigationLabel} id={id}>
      <div className="container gallery">
        <div className="row pb-6 pb-sm-10">
          <div className="col col-md-12 col-lg-11 gallery__rect">
            <span></span>
          </div>
        </div>
        <Gallery images={images} t={props.t} />
        <Button field={props.fields?.FurtherImages} variant="secondary" svg="external-link" />
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_gallery))
