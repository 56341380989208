import React, { useState, useMemo } from 'react'
import FsLightbox from 'fslightbox-react'
import { Image } from '@sitecore-jss/sitecore-jss-react'

/**
 * @param {Object} props
 * @param {Object} props.images
 * @param {Object} props.images.image
 * @param {Object} props.images.Image
 * @param {Object} props.images.Image.value
 * @param {string} props.images.Image.value.src
 */
export const Gallery = ({ images, t }) => {
  const imgSources = useMemo(() => {
    return images.map((image, idx) => (
      <img key={idx} src={image.fields.Image.value.src} alt={image.alt} />
    ))
  }, [images])

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const showFullscreen = (number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number + 1,
    })
  }

  return (
    <div className="row pb-6 pb-sm-10">
      <div className="col col-md-12 col-lg-7 gallery__image--big">
        <a href={images[0].fields.Image.value.src}>
          <Image
            field={images[0].fields.Image}
            onClick={(e) => {
              e.preventDefault()
              showFullscreen(0)
            }}
          />
        </a>
        <button
          className="gallery__image-count__number--mobile"
          onClick={(e) => {
            e.preventDefault()
            showFullscreen(1)
          }}
        >
          {images.length > 1 && (
            <span>
              {' + ' + (images.length - 1)}{' '}
              <span className="visually-hidden">{t('aw_common_gallery_more_images')}</span>
            </span>
          )}
        </button>
      </div>

      <FsLightbox
        toggler={lightboxController.toggler}
        sources={imgSources}
        disableThumbs={true}
        slide={lightboxController.slide}
      />

      <div className="col col-md-12 col-lg-5 pl-5 gallery__images-container">
        <div className="row">
          {images.map((image, index) => {
            if (index === 0 || (index > 3 && images.length !== 5)) return null
            return (
              <a
                href={
                  image &&
                  image.fields &&
                  image.fields.Image &&
                  image.fields.Image.value &&
                  image.fields.Image.value.src
                    ? image.fields.Image.value.src
                    : '#'
                }
                className="col-md-3 col-lg-6 px-lg-2 pt-0 gallery__image--small"
                key={`image-${index}`}
                onClick={(e) => {
                  e.preventDefault()
                  showFullscreen(index)
                }}
              >
                <Image field={image.fields.Image} />
              </a>
            )
          })}
          {images.length > 4 && (
            <div className="col-sm-0 col-md-3 col-lg-6 pt-0 px-2 gallery__image--small">
              <button
                className="gallery__image-count"
                onClick={(e) => {
                  e.preventDefault()
                  showFullscreen(4)
                }}
              >
                <span className="gallery__image-count__number">
                  {' + ' + (images.length - 4)}
                  <span className="visually-hidden">{t('aw_common_gallery_more_images')}</span>
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
