import React from 'react'
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Button } from '../../common/Button'

import './Partners.scss'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Object} props.fields.CtaButton
 * @param {Object} props.fields.Headline
 */
const aw_common_partners = (props) => {
  const id = props.rendering?.uid

  return (
    <div className="component" data-navigation-label={props.params.InPageNavigationLabel} id={id}>
      <div className="container">
        {props.fields?.Headline && (
          <Text field={props.fields?.Headline} tag="h2" className="mb-8" />
        )}
        <ul className="row partners mb-8">
          <Placeholder name="ph-aw-common-partners-insets" rendering={props.rendering} />
        </ul>
        {props.fields?.CtaButton && props.fields?.CtaButton.href && (
          <div className="mt-6 text-center">
            <Button field={props.fields?.CtaButton} variant="secondary" />
          </div>
        )}
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_partners))
