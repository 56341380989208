import React from 'react'
import { Image, Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import './GridInfoImage.scss'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Object} props.fields.Caption
 * @param {Object} props.fields.SubImageText
 */

const aw_common_grid_info_inset_image = (props) => (
  <>
    {props.fields?.Caption && (
      <Text className="mb-4 grid_caption" field={props.fields?.Caption} tag="h4" />
    )}
    {props.fields?.Image && <Image field={props.fields?.Image} loading="lazy" />}
    {props.fields?.SubImageText && (
      <Text className="body" field={props.fields?.SubImageText} tag="p" />
    )}
  </>
)

export default withSitecoreContext()(withTranslation()(aw_common_grid_info_inset_image))
