import React from 'react'
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Button } from '../../common/Button'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Object} props.fields.CtaButton
 * @param {Object} props.fields.Headline
 * @param {Object} props.fields.Image
 * @param {Object} props.fields.Subline
 * @param {Object} props.fields.TopChar
 */
const aw_common_testimonials_inset_image = (props) => (
  <>
    <div className="testimonials-inset__img-container">
      {props.fields?.Image && (
        <div className="testimonials-inset__img">
          <Image field={props.fields?.Image} loading="lazy" />
        </div>
      )}
    </div>
    {props.fields?.Headline && (
      <Text
        field={props.fields?.Headline}
        tag="blockquote"
        className="h2 testimonials-inset__quote"
      />
    )}
    {props.fields?.Subline && <RichText field={props.fields?.Subline} />}
    {props.fields?.CtaButton && props.fields?.CtaButton.href && (
      <Button field={props.fields?.CtaButton} variant="secondary" />
    )}
  </>
)

export default withSitecoreContext()(withTranslation()(aw_common_testimonials_inset_image))
