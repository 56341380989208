import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Object} props.fields.HtmlCode
 * @property {Object} sitecoreContext
 * @property {boolean} sitecoreContext.pageEditing
 */

const aw_common_plainhtml = (props) => {
  const id = props.rendering?.uid
  const isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing

  return (
    isEditing ? 
    <div
      className="plain-html container component mb-0"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}>[Edit Plain HTML here]</div> : 
    <div
      className="plain-html container component mb-0"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
      dangerouslySetInnerHTML={ { __html: props.fields?.HtmlCode?.value } }
    >
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_plainhtml))
