import React from 'react'
import { Text, Link, Image, RichText } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { getBacklinkData } from '../../util'
import { Icon } from '../../common/Icon'
import '../aw_common_page_intro_text/PageIntro.scss'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {boolean} props.params.DisplayBreadcrumb
 * @param {Object} props.fields
 * @param {string} props.fields.Headline
 * @param {string} props.fields.Subline
 * @param {Object} props.sitecoreContext
 * @param {Array} props.sitecoreContext.breadcrumb_navigation
 * @param {Object} props.sitecoreContext.route
 * @param {Object} props.sitecoreContext.route.fields
 * @param {string} props.sitecoreContext.route.fields.PageTitle
 * @param {string} props.sitecoreContext.route.fields.PageIntro
 * @param {string} props.sitecoreContext.route.fields.PageImage
 */

const aw_common_page_intro_image = (props) => {
  const breadcrumbs = props.sitecoreContext.breadcrumb_navigation
  const id = props.rendering?.uid
  let title
  let text
  let image

  if (props.fields) {
    title = props.fields.Headline
    text = props.fields.Subline
    image = props.fields.Image
  } else {
    title = props.sitecoreContext?.route?.fields?.PageTitle
    text = props.sitecoreContext?.route?.fields?.PageIntro
    image = props.sitecoreContext?.route?.fields?.PageImage
  }

  return (
    <div
      className="page-intro container component"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
    >
      {props.params.DisplayBreadcrumb === '1' && (
        <div className="page-intro-breadcrumbs">
          <a className="h4 btn btn--tertiary" href={getBacklinkData(breadcrumbs).link}>
            <Icon name="arrow" rotate={180} size={16} />
            {getBacklinkData(breadcrumbs).label}
          </a>
          {breadcrumbs && breadcrumbs.length > 1 && (
            <nav aria-label={props.t('aw_common_breadcrumb_label')}>
              <ol className="breadcrumb">
                {breadcrumbs.map((link, index) => (
                  <li key={link.link} className="breadcrumb-item">
                    {index > 0 && ' / '}
                    <Link field={{ value: { href: link.link, text: link.label } }} />
                  </li>
                ))}
              </ol>
            </nav>
          )}
        </div>
      )}
      <div className="col-12 col-md-8 col-lg-6">
        {title && <Text field={title} tag="h1" />}
        {text && <RichText field={text} />}
      </div>
      {image && (
        <div className="col-12">
          <Image field={image} loading="lazy" />
        </div>
      )}
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_page_intro_image))
