import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 */
const aw_common_offices = (props) => {
  const id = props.rendering?.uid
  return (
    <div className="component" data-navigation-label={props.params.InPageNavigationLabel} id={id}>
      <div className="container">
        <div className="row">
          <Placeholder name="ph-aw-common-offices-insets" rendering={props.rendering} />
        </div>
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_offices))
