import React from 'react'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Button } from '../../common/Button'
import '../aw_common_teaserbox_text/TeaserBox.scss'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Object} props.fields.Subheadline
 * @param {Object} props.fields.Headline
 * @param {Object} props.fields.CtaButton
 * @param {Object} props.fields.CtaLink
 * @param {Object} props.fields.Text
 * @property {boolean} props.rendering
 */

const aw_common_teaserbox_events = (props) => {
  const id = props.rendering?.uid

  return (
    <div className="teaserbox container component" id={id}>
      <div className="row" data-navigation-label={props.params.InPageNavigationLabel}>
        <Text className="mb-4 mb-md-6" field={props.fields?.Subheadline} tag="h4" />
        <div className="col-12 col-md-6 col-lg-4">
          <Text field={props.fields?.Headline} tag="h2" />
          {((props.fields?.CtaButton && props.fields?.CtaButton.href) ||
            (props.fields?.CtaLink && props.fields?.CtaLink.href)) && (
            <div className="d-flex flex-wrap justify-content-start mt-4 mt-md-6">
              {props.fields?.CtaButton.href && (
                <Button field={props.fields?.CtaButton} variant="secondary" className="mb-2 me-4" />
              )}
              {props.fields?.CtaLink.href && (
                <Button field={props.fields?.CtaLink} variant="tertiary" />
              )}
            </div>
          )}
        </div>
        {props.fields?.Text && (
          <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
            <RichText field={props.fields?.Text} />
          </div>
        )}
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mt-8 mt-lg-12">
        <Placeholder name="ph-aw-common-teaserbox-events" rendering={props.rendering} />
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_teaserbox_events))
