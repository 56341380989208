const config = require('./temp/config')

/**
 * Check is disconnected mode started
 * @returns {boolean}
 */
const isDisconnected = () => /localhost/i.test(config.sitecoreApiHost)

module.exports.isDisconnected = isDisconnected

/**
 * Get hostname which used to access application, in disconnected mode it can be localhost or ip address
 * @returns {string} hostname
 */
module.exports.getHostname = () =>
  isDisconnected() ? window.location.origin : config.sitecoreApiHost

/**
 * Transform UTC date into human readable date in DD.MM. or DD.MM.YYYY format
 * @returns {string}
 */
module.exports.formatDate = (inputDate, outputFormat) => {
  const date = new Date(inputDate)

  const formatMonth = (date) => {
    const realMonth = date.getMonth() + 1
    return realMonth < 10 ? '0' + realMonth : realMonth.toString()
  }

  const day = date.getDate().toString()
  const month = formatMonth(date)
  const year = date.getFullYear().toString()

  if (outputFormat === 'dayMonth') {
    return `${day}.${month}.`
  } else {
    return `${day}.${month}.${year}`
  }
}

/**
 * Parse given `content` and return src value or content of all `<script>` tags.
 * @param {string} content
 * @returns {Array.<{ external: boolean, value: string }>}
 */
module.exports.getScripts = (content) => {
  if (!content) return []

  const scriptTags = content.match(/<script[\s\S]*?\/script>/g)

  return scriptTags
    .map((script) => {
      const matchSrc = script.match(/<script.*src="(.*)".*>/s) // try to match value of `src` attribute
      const matchContent = script.match(/<script.*>(.*)<\/script>/s) // try to match content of script

      // check if script contains `src`
      if (matchSrc) {
        return {
          external: true,
          value: matchSrc[1],
        }
      }

      // otherwise return content of script
      if (matchContent) {
        return {
          external: false,
          value: matchContent[1],
        }
      }

      return null
    })
    .filter((script) => script)
}

module.exports.isMobileDevice = () => {
  if (!this.isClient()) return false

  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true
  } else {
    return false
  }
}

/**
 * Check if `window` object is available.
 * @returns {boolean} true if application is rendered in browser
 */
module.exports.isClient = () => typeof window !== 'undefined'

/**
 * Get link and label keys from backlink array.
 * @returns {Object} with href link and label
 */
module.exports.getBacklinkData = (linkArray) => {
  if (linkArray.length === 1) {
    return { link: linkArray[0].link, label: linkArray[0].label }
  }
  if (linkArray.length > 1) {
    // return second to last
    return {
      link: linkArray[linkArray.length - 2].link,
      label: linkArray[linkArray.length - 2].label,
    }
  }
}

/**
 * Check whether given `jssLinkField` has non-empty href.
 * @typedef {Object} JSSLinkField
 * @property {Object} value
 * @property {string} value.href
 * @property {string} value.text
 * @property {string} value.url
 * @property {string} value.target
 * @property {string} value.title
 *
 * @param {JSSLinkField | null | undefined} jssLinkField
 * @returns {boolean}
 */
module.exports.hasHref = (jssLinkField) => Boolean(jssLinkField?.value?.href)
