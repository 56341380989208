import React from 'react'
import { Image, Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import './AwardsInset.scss'

const aw_common_awards_inset = (props) => (
  <div className="align-items-center">
    <div className="col-12 awards-inset">
      <div className="row align-items-center">
        {props.fields?.Image && (
          <div className="col-lg-3 col-md-4 col-6 px-3 mb-3 mb-md-0">
            <Image className="awards-inset__img" field={props.fields?.Image} loading="lazy" />
          </div>
        )}
        {props.fields?.Title && (
          <div className="col-lg-9 col-md-8 col-12 px-3">
            <Text className="awards-inset__text" field={props.fields?.Title} />
          </div>
        )}
      </div>
    </div>
  </div>
)

export default withSitecoreContext()(withTranslation()(aw_common_awards_inset))
