import React from 'react'
import { Link as JssLink } from '@sitecore-jss/sitecore-jss-react'
import classNames from 'classnames'

import { Icon } from '../Icon'

/**
 * @param {Object} props
 * @param {Object} props.field
 * @param {"h3" | "body" | "caption" | "underlined" } props.variant
 * @param {boolean} props.disabled
 */
export function Link({ field, variant, disabled }) {
  const classes = classNames({
    body: variant === undefined,
    [`${variant}`]: variant && variant !== 'h3',
    disabled: disabled,
    'h3-link': variant === 'h3',
  })

  if (variant === 'h3') {
    return (
      <h3 className={classes}>
        <JssLink field={field} />
        <Icon className="display-hover" name="arrow" size={24} />
      </h3>
    )
  } else {
    return <JssLink field={field} className={classes} />
  }
}
