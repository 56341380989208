import React from 'react'
import { Image, Text, DateField } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { formatDate } from '../../util'

import { Icon } from '../../common/Icon'
import '../aw_common_events_normal/EventsList.scss'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {string} props.fields.Image
 * @param {Object} props.fields.DateFrom
 * @param {Object} props.fields.DateTo
 * @param {string} props.fields.Name
 * @param {string} props.fields.targetUrl
 */

const aw_common_events_inset = (props) => (
  <div className="col">
    <div className="events-list-item">
      <div className="events-list-item__image">
        <Image field={props.fields?.Image} />
      </div>
      <div className="events-list-item__info">
        <div className="events-list-item__info-dates">
          <span className="body">
            {props.fields?.DateFrom && (
              <DateField
                field={props.fields?.DateFrom}
                render={() => formatDate(props.fields?.DateFrom.value, 'dayMonth')}
              />
            )}
            {props.fields?.DateFrom && props.fields?.DateTo && ' – '}
            {props.fields?.DateTo && (
              <DateField
                field={props.fields?.DateTo}
                render={() => formatDate(props.fields?.DateTo.value)}
              />
            )}
          </span>
        </div>
        <Text className="events-list-item__info-title" field={props.fields?.Name} tag="h3" />
        {props.fields.targetUrl && (
          <a className="btn btn--tertiary" href={props.fields.targetUrl}>
            {props.t('aw_common_events_show_event')}
            <Icon name="arrow" size={18} />
          </a>
        )}
      </div>
    </div>
  </div>
)

export default withSitecoreContext()(withTranslation()(aw_common_events_inset))
