import React, { useState, useEffect, useRef } from 'react'
import FocusTrap from 'focus-trap-react'
import { Button } from '../../common/Button'

import './Navigation.scss'
import { Icon } from '../../common/Icon'
import { Menu } from './Menu'
import classNames from 'classnames'
import Media from 'react-media'

import { isClient } from '../../util'
import LanguageSwitcher from '../../common/LanguageSwitcher/LanguageSwitcher'

/**
 * @typedef {Object} Item
 * @property {Array<Item>} childs
 * @property {string} displayName
 * @property {Object} fields
 * @property {string} id
 * @property {string} name
 * @property {string} template
 *
 * @typedef {Object} Language
 * @property {string} label
 * @property {string} url
 * @property {string} value
 * @property {string} title
 *
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Array<Item>} props.fields.items
 * @param {function(string): string} props.t
 * @param {Object} props.sitecoreContext
 * @param {Array<Language>} props.sitecoreContext.language_switcher
 * @param {string} props.sitecoreContext.language
 * @param {string} props.sitecoreContext.homepage_url
 */
export const Navigation = ({ ...props }) => {
  const [mobileMenuVisibility, setMobileMenuVisibility] = useState(false)
  const t = props.t
  const id = props.rendering?.uid
  const toggleBtn = useRef(null)
  const languages = props.sitecoreContext?.language_switcher ?? []
  const currentLanguage = props.sitecoreContext.language ?? 'en'

  const logoHref = props.sitecoreContext?.homepage_url ?? '/'

  useEffect(() => {
    if (!isClient()) return

    const closeMenu = ({ key }) => {
      if (key === 'Escape' && mobileMenuVisibility) {
        setMobileMenuVisibility(false)
        toggleBtn.current.focus()
      }
    }

    if (mobileMenuVisibility) {
      window.addEventListener('keydown', closeMenu)
    }
    return () => {
      window.removeEventListener('keydown', closeMenu)
    }
  }, [mobileMenuVisibility])

  return (
    <div className="navigation navbar navbar-expand-xxl" id={id}>
      <FocusTrap active={mobileMenuVisibility}>
        <div className="container navigation__container">
          <a className="navbar-brand" href={logoHref} aria-label="ACTUM Digital">
            <Icon width={170} height={25} name="actum-logo" />
          </a>

          <button
            ref={toggleBtn}
            className="navigation__toggle-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={mobileMenuVisibility}
            onClick={() => setMobileMenuVisibility(!mobileMenuVisibility)}
          >
            <span>
              {!mobileMenuVisibility ? t('aw_navigation_main_menu') : t('aw_navigation_main_close')}
            </span>
            {!mobileMenuVisibility ? (
              <Icon size={25} name="menu" />
            ) : (
              <Icon size={25} name="close" />
            )}
          </button>

          <Media query="(max-width: 1399px)" defaultMatches={false}>
            {(matches) => {
              // mobile markup
              if (matches) {
                return (
                  <div
                    className={classNames('navbar-collapse', 'collapse', {
                      show: mobileMenuVisibility,
                    })}
                    id="navbarSupportedContent"
                  >
                    <Menu items={props.fields.items} type="mobile" t={t} />
                    {languages.length > 0 && (
                      <LanguageSwitcher
                        languages={languages}
                        currentLanguageCode={currentLanguage}
                        type="inline"
                      />
                    )}
                  </div>
                )
              }

              // desktop markup
              return (
                <div className="navbar-collapse collapse" id="navbarSupportedContent">
                  <Menu items={props.fields.items} t={t} />
                  <div className="navigation__inputs">
                    <Button
                      field={props.fields.items[props.fields?.items?.length - 1].fields?.Link}
                      variant="primary"
                      text={
                        props.fields.items[props.fields?.items?.length - 1].fields?.Title?.value
                      }
                    />
                    {languages.length > 0 && (
                      <LanguageSwitcher
                        languages={languages}
                        currentLanguageCode={currentLanguage}
                      />
                    )}
                  </div>
                </div>
              )
            }}
          </Media>
        </div>
      </FocusTrap>
    </div>
  )
}
