import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Object} props.fields.Caption
 * @param {Object} props.fields.Content
 */

const aw_common_grid_info_inset_text = (props) => (
  <>
    {props.fields?.Caption && (
      <Text className="mb-4 grid_caption" field={props.fields?.Caption} tag="h3" />
    )}
    {props.fields?.Content && <RichText field={props.fields?.Content} />}
  </>
)

export default withSitecoreContext()(withTranslation()(aw_common_grid_info_inset_text))
