import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import CareerPosition from './CareerPosition'
import './CareerPosition.scss'

const aw_common_career_positions = (props) => {
  const items = props.fields?.Selected
  const id = props.rendering?.uid

  return (
    <div
      className="career-positions container component"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
    >
      {props.fields?.Headline && (
        <Text field={props.fields?.Headline} tag="h2" className="career-positions__heading h3" />
      )}
      <div className="row row-cols-sm-2 row-cols-1">
        {items &&
          items.map((item) => (
            <div key={item.id} className="col">
              <CareerPosition
                displayName={item.fields.PageTitle.value}
                url={item.url}
                company={item.fields.Company}
                posted={item.fields.Posted}
                project={item.fields.Project}
                unit={item.fields.Unit}
                t={props.t}
                {...props}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_career_positions))
