import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import FocusTrap from 'focus-trap-react'
import { isClient } from '../../util'

import './videoModal.scss'
import { Icon } from '../../common/Icon'

const VideoModal = ({ videoId, t }) => {
  const videoPlayerRef = React.useRef()

  const closeModal = () => {
    if (!isClient()) return
    const closeModalEvent = new CustomEvent('closeModal')
    window.dispatchEvent(closeModalEvent)
  }

  return (
    <FocusTrap>
      <div className="video-modal">
        <div className="video-modal__header">
          <button
            className="video-modal__close-button"
            aria-label={t('aw_navigation_main_close')}
            onClick={closeModal}
          >
            <Icon name="close" color="#fff" size={48} />
          </button>
        </div>
        <div className="video-modal__wrapper">
          <iframe
            ref={videoPlayerRef}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            className="video-modal__video"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="video-modal__backdrop"> </div>
      </div>
    </FocusTrap>
  )
}

export default withSitecoreContext()(withTranslation()(VideoModal))
