import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Button } from '../../common/Button'

import '../aw_common_awards_inset/AwardsInset.scss'

const aw_common_awards = (props) => {
  const id = props.rendering?.uid

  return (
    <div className="component" data-navigation-label={props.params.InPageNavigationLabel} id={id}>
      <div className="container">
        <div className="row align-items-center awards">
          <div className="col-md-6 col-sm-12">
            {props.fields?.Headline && (
              <Text className="awards-headline h4" field={props.fields?.Headline} tag="h2" />
            )}
            {props.fields?.Subheadline && (
              <Text className="awards-headline__sub h2" field={props.fields?.Subheadline} tag="p" />
            )}
            <div className="d-none d-md-inline-block">
              {props.fields?.CtaButton && props.fields?.CtaButton.href && (
                <Button field={props.fields?.CtaButton} variant="secondary" />
              )}
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <Placeholder name="ph-aw-common-awards-insets" rendering={props.rendering} />
            <div className="d-inline-block d-md-none mt-3">
              {props.fields?.CtaButton && props.fields?.CtaButton.href && (
                <Button field={props.fields?.CtaButton} variant="secondary" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_awards))
