import React from 'react'

import './Spinner.scss'

export const Spinner = () => (
  <div className="spinner" aria-hidden="true">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

/**
 * @param {Object} props
 * @param {boolean} props.loading
 * @param {JSX.Element | ReactNode} props.children
 */
export const SpinnerOverlay = ({ loading, children }) => {
  return (
    <div className="spinner-overlay">
      {children}
      {loading && (
        <>
          <div className="spinner__backdrop" />
          <Spinner />
        </>
      )}
    </div>
  )
}
