import React from 'react'
import { Link, Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Icon } from '../../common/Icon'
import './ContactUs.scss'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {Object} props.fields
 * @param {string} props.fields.Email
 * @param {string} props.fields.Phone
 * @property {boolean} props.rendering
 * @property {Object} props.sitecoreContext
 * @property {boolean} props.sitecoreContext.pageEditing
 */

const aw_common_contact_us = (props) => {
  const isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing
  const id = props.rendering?.uid

  return (
    <div
      className="contact-us container component"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
    >
      <div className="row contact-us-row">
        {props.fields?.Email && (
          <div className="col-12 col-md-6 col-lg-8 contact-us-row-data contact-us-row-data--mail">
            <Icon name="mail-fill" size={24} />
            <Link className="h2" field={props.fields?.Email} />
          </div>
        )}
        {props.fields?.Phone && (
          <div className="col-12 col-md-6 col-lg-4 contact-us-row-data contact-us-row-data--phone">
            <Icon name="phone-fill" size={24} />
            <a className="h2" href={`tel:${props.fields?.Phone.value}`}>
              {isEditing ? <Text field={props.fields?.Phone} /> : props.fields?.Phone.value}
            </a>
          </div>
        )}
      </div>
      <hr />
      <div className="row">
        <Placeholder name="ph-aw-common-contact-us-insets" rendering={props.rendering} />
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_contact_us))
