import React from 'react'
import { Image, Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import './BigImage.scss'
import classNames from 'classnames'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 *
 * @param {Object} props.fields
 * @param {Object} props.fields.Caption
 * @param {string} props.fields.Caption.value
 * @param {Object} props.fields.Image
 * @param {Object} props.fields.Image.value
 * @param {string} props.fields.Image.value.alt
 * @param {string} props.fields.Image.value.height
 * @param {string} props.fields.Image.value.width
 * @param {string} props.fields.Image.value.src
 *
 * @param {Object} props.rendering
 * @param {string} props.rendering.uid
 * @param {Object} props.rendering.params
 * @param {"normal" | "overflow" | "wide"} props.rendering.params.Width
 */

const aw_common_big_image = (props) => {
  const id = props.rendering?.uid

  /**
   * @value "normal" image is same width as container
   * @value "overflow" image overflows container
   * @value "wide" image is same width as screen width
   */
  const imgWidth = props.rendering?.params.Width

  const classes = classNames('component', 'container', 'big-image', {
    'img__overflow': imgWidth === 'overflow',
    'img__full_width': imgWidth === 'wide',
  })

  return (
    <div className={classes} data-navigation-label={props.params.InPageNavigationLabel} id={id}>
      <figure className="mb-0">
        {props.fields?.Image && (
          <Image className="big-image__img" field={props.fields?.Image} loading="lazy" />
        )}
        {props.fields?.Caption && (
          <Text className="big-image__text" field={props.fields?.Caption} tag="figcaption" />
        )}
      </figure>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_big_image))
