import React from 'react'

import './TextArea.scss'

import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

/**
 * @callback onChange
 * @param {string} key
 * @param {string} value
 * @param {boolean} isValid
 * @param {Array<string>} errors
 */

/**
 * @param {Object} props
 * @param {Object} props.tracker
 * @param {Object} props.field
 * @param {Object} props.field.model
 * @param {string} props.field.model.title
 * @param {string} props.field.model.cssClass
 * @param {string} props.field.model.labelCssClass
 * @param {string} props.field.model.name
 * @param {number} props.field.model.maxLength
 * @param {Object} props.field.valueField
 * @param {string} props.field.valueField.id
 * @param {Array<string>} props.errors
 * @param {onChange} props.onChange
 * @param {string} props.value
 * @param {function(string): string} props.t
 */
const TextArea = ({ tracker, field, errors, onChange, value, t }) => {
  const handleChange = (e) => {
    const newValue = e.target.value

    let valid = true
    let errorMessages = []

    if (field.model.required && !newValue) {
      valid = false
      errorMessages.push(`${field.model.title} ${t('aw_common_form_field_required')}`)
    }
    onChange(field.valueField.name, newValue, valid, errorMessages)
  }

  const onFocus = (e) => {
    tracker.onFocusField(field, e.target.value)
  }

  const onBlur = (e) => {
    tracker.onBlurField(field, e.target.value, errors)
  }

  const classes = classNames('text-area', { 'text-area--error': errors.length })

  return (
    <div className={classes}>
      <textarea
        className={field.model.cssClass}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={field.model.maxLength}
        id={field.valueField.id}
        name={field.model.name}
        placeholder={field.model.title}
        value={value}
      ></textarea>
      <label htmlFor={field.valueField.id} className={classNames(field.model.labelCssClass)}>
        {field.model.title}
      </label>
      {errors && errors.length > 0 && <div className="text-area__error">{errors[0]}</div>}
    </div>
  )
}

export default withTranslation()(TextArea)
