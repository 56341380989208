import React, { useState } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import VideoModal from '../aw_common_video_modal'
import { isClient } from '../../util'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 */
const ModalProvider = () => {
  const initialModalConfig = {
    type: '',
    videoId: '',
    action: '',
    open: false,
  }

  const [modalConfig, setModalConfig] = useState(initialModalConfig)

  if (isClient()) {
    window.addEventListener('openModal', ({ detail }) => {
      setModalConfig({ ...modalConfig, ...detail, open: true })
    })

    window.addEventListener('closeModal', () => {
      setModalConfig(initialModalConfig)
    })

    window.addEventListener('keyup', (e) => {
      if (modalConfig.open && e.key === 'Escape') {
        setModalConfig(initialModalConfig)
      }
    })
  }

  const renderContent = () => {
    switch (modalConfig.type) {
      case 'videoModal': {
        return <VideoModal videoId={modalConfig.videoId} />
      }
      default:
        break
    }
  }

  return <div className="modal-provider">{renderContent()}</div>
}

export default withSitecoreContext()(withTranslation()(ModalProvider))
