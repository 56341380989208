import React from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import '../aw_common_teaserbox_text/TeaserBox.scss'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Object} props.fields.Image
 */

const aw_common_teaserbox_image_inset = (props) => (
  <div className="col">
    {props.fields?.Image && <Image field={props.fields?.Image} loading="lazy" />}
  </div>
)

export default withSitecoreContext()(withTranslation()(aw_common_teaserbox_image_inset))
