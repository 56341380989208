import React from 'react'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Button } from '../../common/Button'
import './TeaserBox.scss'
import { hasHref } from '../../util'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Object} props.fields.Subheadline
 * @param {Object} props.fields.Headline
 * @param {Object} props.fields.CtaButton
 * @param {Object} props.fields.CtaLink
 * @param {Object} props.fields.Text
 */

const aw_common_teaserbox_text = (props) => {
  const id = props.rendering?.uid

  return (
    <div className="teaserbox container component">
      <div className="row" data-navigation-label={props.params.InPageNavigationLabel}>
        <Text className="mb-4 mb-md-6 h4" field={props.fields?.Subheadline} tag="p" id={id} />
        <div className="col-12 col-md-6 col-lg-4">
          <Text field={props.fields.Headline} tag="h2" />
          {(hasHref(props.fields?.CtaButton) || hasHref(props.fields?.CtaLink)) && (
            <div className="d-flex flex-wrap justify-content-start mt-4 mt-md-6">
              {hasHref(props.fields?.CtaButton) && (
                <Button field={props.fields?.CtaButton} variant="secondary" className="mb-2 me-4" />
              )}
              {hasHref(props.fields?.CtaButton) && (
                <Button field={props.fields.CtaLink} variant="tertiary" />
              )}
            </div>
          )}
        </div>
        {props.fields.Text && (
          <div className="col-12 col-md-6 col-lg-6 offset-lg-1 mt-8 mt-md-0">
            <RichText field={props.fields?.Text} />
          </div>
        )}
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_teaserbox_text))
