import React, { useState } from 'react'
import classNames from 'classnames'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Icon } from '../../common/Icon'
import { Collapse } from '../../common/Collapse'

import '../aw_common_faq/Faq.scss'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Object} props.fields.Caption
 * @param {Object} props.fields.Content
 */

export const FaqCollapse = ({ props }) => {
  const [isOpen, setOpen] = useState(false)

  const id = props.rendering.uid

  return (
    <div className="faq__collapse">
      <button
        type="button"
        className="faq__collapse-btn"
        onClick={() => setOpen((prev) => !prev)}
        id={`faqCollapseBtn_${id}`}
        aria-expanded={isOpen}
        aria-controls={`faqCollapseContent_${id}`}
      >
        {props.fields?.Caption && (
          <Text field={props.fields?.Caption} tag="span" className="h3 d-block" />
        )}
        <Icon
          name="plus"
          rotate={isOpen ? 270 : 90}
          size={24}
          className={classNames({
            'faq__collapse-icon': true,
            'faq__collapse-icon--hidden': isOpen,
          })}
        />
        <Icon
          name="minus"
          rotate={isOpen ? 180 : 0}
          size={24}
          className={classNames({
            'faq__collapse-icon': true,
            'faq__collapse-icon--hidden': !isOpen,
            'faq__collapse-icon--open': isOpen,
          })}
        />
      </button>
      <Collapse
        isOpen={isOpen}
        id={`faqCollapseContent_${id}`}
        aria-labelledby={`faqCollapseBtn_${id}`}
      >
        <div className="faq__collapse-inner">
          {props.fields?.Content && <RichText className="body" field={props.fields?.Content} />}
        </div>
      </Collapse>
    </div>
  )
}

const aw_common_faq_inset = (props) => <FaqCollapse props={props} />

export default withSitecoreContext()(withTranslation()(aw_common_faq_inset))
