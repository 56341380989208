import React, { useMemo } from 'react'
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import '../src/scss/main.scss'
import { getScripts, isClient } from './util'
import { ReactComponent as SVGSprite } from './svg/sprite.svg'
import { Icon } from './common/Icon'
import ModalProvider from './components/aw_common_modal_provider'
// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
// import "./assets/app.css";

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ sitecoreContext, route, t }) => {
  // eg. robots=noindex&googlebot=noindex
  const customMetaQuery = route.fields.Attributes?.value ?? ''
  const customMetaEntries = useMemo(() => {
    const params = new URLSearchParams(customMetaQuery)
    return Array.from(params.entries())
  }, [customMetaQuery])

  return (
    <>
      <Helmet>
        <meta name="description" content={route.fields.MetaDescription?.value ?? ''} />
        <meta name="keywords" content={route.fields.MetaKeywords?.value ?? ''} />
        <link rel="canonical" href={sitecoreContext.cannonical_url} />
        {customMetaEntries.map((entry, idx) => (
          <meta key={idx} name={entry[0]} content={entry[1]} />
        ))}
        {isClient() && <script>window.dataLayer = window.dataLayer || [];</script>}
        {getScripts(sitecoreContext.scripts_head).map((script, idx) => {
          if (script.external) {
            return <script key={idx} src={script.value} />
          } else {
            return <script key={idx}>{script.value}</script>
          }
        })}
        <title>{route.fields.MetaTitle?.value ?? route.fields.PageTitle?.value}</title>
      </Helmet>

      <div dangerouslySetInnerHTML={{ __html: sitecoreContext.scripts_body_top }} />
      <VisitorIdentification />

      <div className="d-none">{isClient() && <SVGSprite />}</div>

      <header>
        <a href="#main_content" id="skip-link" className="btn btn--unique">
          {t('aw_navigation_skip_to_content')}
          <Icon name="arrow" size={18} />
        </a>
        <Placeholder name="ph-aw-header" rendering={route} />
      </header>
      <main id="main_content" tabIndex="-1">
        <ModalProvider />
        <Placeholder name="ph-aw-main" rendering={route} />
      </main>
      <Placeholder name="ph-aw-footer" rendering={route} />
      <div dangerouslySetInnerHTML={{ __html: sitecoreContext.scripts_body_bottom }} />
    </>
  )
}

export default withSitecoreContext()(withTranslation()(Layout))
