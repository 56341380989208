import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Button } from '../../common/Button'

import './OfficeInset.scss'
import { Icon } from '../../common/Icon'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Object} props.fields.Address
 * @param {string} props.fields.Address.value
 * @param {Object} props.fields.Location
 * @param {string} props.fields.Location.value
 * @param {Object} props.fields.Link
 */

const aw_common_offices_inset = (props) => (
  <div className="offices col-md-6 p-3">
    <div className="offices-inset p-5 pt-10">
      <Icon name="map-pin-fill" size={24} fill="#d93c31" />
      {props.fields?.Address && <RichText field={props.fields?.Address} className="address" />}
      {props.fields?.Location && <Text field={props.fields?.Location} tag="h3" className="h2" />}
      {props.fields?.Link && <Button field={props.fields?.Link} variant="tertiary" />}
    </div>
  </div>
)

export default withSitecoreContext()(withTranslation()(aw_common_offices_inset))
