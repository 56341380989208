import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Button } from '../../common/Button'

import './Promo.scss'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields.DisplayBackground
 * @param {string} props.fields.DisplayBackground.value
 * @param {Object} props.fields.BackgroundSVG
 * @param {string} props.fields.BackgroundSVG.value
 * @param {Object} props.sitecoreContext
 * @param {boolean} props.sitecoreContext.pageEditing
 */
const aw_common_promo = (props) => {
  const { t } = props
  const id = props.rendering?.uid

  return (
    <div className="container component">
      <div data-navigation-label={props.params.InPageNavigationLabel} className="promo" id={id}>
        {props.fields?.DisplayBackground.value && (
          <svg
            height="100%"
            viewBox="0 0 49 20"
            focusable={false}
            aria-hidden="true"
            className="background"
            dangerouslySetInnerHTML={{ __html: props.fields?.BackgroundSVG.value }}
          />
        )}

        <Text field={props.fields?.Headline} tag="h2" />

        <div className="promo__controls">
          {props.fields?.CtaButton?.value?.text && (
            <Button field={props.fields?.CtaButton} variant="unique" />
          )}
          {props.fields?.CtaButton?.value?.text && props.fields?.CtaLink?.value?.text && (
            <span>{t('aw_common_promo_or')}</span>
          )}
          {props.fields?.CtaLink?.value?.text && (
            <Button field={props.fields?.CtaLink} variant="tertiary" />
          )}
        </div>
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_promo))
