import React from 'react'
import { withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import './Testimonials.scss'
import Container from './Container'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.rendering
 * @param {Object} props.rendering.placeholders
 * @param {Array} props.rendering.placeholders.ph-aw-common-testimonials-insets
 * @param {function(string): string} t
 */
const aw_common_testimonials = (props) => {
  const id = props.rendering?.uid
  const isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing

  return (
    <div
      data-navigation-label={props.params.InPageNavigationLabel}
      className="testimonials container component"
      id={id}
    >
      {!isEditing && (
        <Container
          name="ph-aw-common-testimonials-insets"
          rendering={props.rendering}
          t={props.t}
        />
      )}
      {isEditing && (
        <Placeholder name="ph-aw-common-testimonials-insets" rendering={props.rendering} />
      )}
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_testimonials))
