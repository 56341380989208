import React, { useState } from 'react'
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react'
import { Icon } from '../../common/Icon'

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {Array} props.ph-aw-common-testimonials-insets
 * @param {function(string): string} t
 */
const Container = (props) => {
  const insets = props['ph-aw-common-testimonials-insets']
  const [index, setIndex] = useState(0)
  const { t } = props

  const forward = () => {
    if (index + 1 < insets.length) {
      setIndex(index + 1)
    } else {
      setIndex(0)
    }
  }

  const backward = () => {
    if (index - 1 >= 0) {
      setIndex(index - 1)
    } else {
      setIndex(insets.length - 1)
    }
  }

  return (
    <div className="testimonials-inset">
      {insets && insets.length > 1 && (
        <button
          className="testimonials__left-arrow"
          onClick={backward}
          aria-label={t('aw_common_testimonials_prev_label')}
        >
          <Icon name="arrow" size={32} rotate={180} />
        </button>
      )}
      {insets && insets.length > 1 && (
        <button
          className="testimonials__right-arrow"
          onClick={forward}
          aria-label={t('aw_common_testimonials_next_label')}
        >
          <Icon name="arrow" size={32} />
        </button>
      )}
      {insets && insets.length > 0 && insets[index]}
    </div>
  )
}

export default withPlaceholder('ph-aw-common-testimonials-insets')(Container)
