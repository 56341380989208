import React, { useState, useRef, useLayoutEffect, useCallback } from 'react'
import classNames from 'classnames'
import { isClient } from '../../util'

import './Collapse.scss'

// inspired by https://css-tricks.com/using-css-transitions-auto-dimensions/

/**
 * @param {Object} props
 * @param {boolean} props.isOpen
 */
export const Collapse = ({ isOpen, children, className, ...rest }) => {
  const [isTransitioning, setTransitioning] = useState(false)
  const containerRef = useRef(null)

  const expandContainer = useCallback((container) => {
    setTransitioning(true)
    const height = container.scrollHeight

    container.style.height = `${height}px`
    container.addEventListener('transitionend', function transitionEndListener() {
      container.removeEventListener('transitionend', transitionEndListener)
      container.style.height = 'auto'
      setTransitioning(false)
    })
  }, [])

  const collapseContainer = useCallback((container) => {
    setTransitioning(true)
    const height = container.scrollHeight

    container.addEventListener('transitionend', function transitionEndListener() {
      container.removeEventListener('transitionend', transitionEndListener)
      container.style.height = '0px'
      setTransitioning(false)
    })

    requestAnimationFrame(() => {
      container.style.height = `${height}px`

      requestAnimationFrame(() => {
        container.style.height = '0px'
      })
    })
  }, [])

  useLayoutEffect(() => {
    if (!isClient) return

    if (isOpen) {
      expandContainer(containerRef.current)
    } else {
      collapseContainer(containerRef.current)
    }
  }, [isOpen, expandContainer, collapseContainer])

  return (
    <div
      className={classNames({
        collapse: !isTransitioning,
        'collapse--transitioning': isTransitioning,
        'collapse--visible': isOpen && !isTransitioning,
        [className]: Boolean(className),
      })}
      ref={containerRef}
      aria-hidden={!isOpen ? true : undefined}
      {...rest}
    >
      {children}
    </div>
  )
}
