import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Button } from '../../common/Button'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Object} props.fields.CtaButton
 * @param {Object} props.fields.Headline
 * @param {Object} props.fields.Image
 * @param {Object} props.fields.Subline
 * @param {Object} props.fields.TopChar
 */

const aw_common_testimonials_inset_text = (props) => {
  const { t, fields } = props
  return (
    <figure aria-label={t('aw_common_testimonials_label')}>
      {fields?.TopChar && (
        <Text
          field={fields?.TopChar}
          tag="span"
          className="testimonials-inset__top-char"
          aria-hidden="true"
        />
      )}
      {fields?.Headline && (
        <Text field={fields?.Headline} tag="blockquote" className="h2 testimonials-inset__quote" />
      )}
      <figcaption aria-label={t('aw_common_testimonials_author_label')}>
        {fields?.Subline && <RichText field={fields?.Subline} />}
      </figcaption>
      {fields?.CtaButton && fields?.CtaButton.href && (
        <Button field={fields?.CtaButton} variant="secondary" />
      )}
    </figure>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_testimonials_inset_text))
