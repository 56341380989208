import React from 'react'
import classNames from 'classnames'
import { Button } from '../Button'

/**
 * @param {Object} props
 * @param {Object} props.field
 * @param {Object} props.field.model
 * @param {string} props.field.model.title
 * @param {string} props.field.model.cssClass
 * @param {string} props.field.model.name
 */
export function SubmitButton({ field }) {
  return (
    <Button
      field={{ value: { text: field.model.title } }}
      name={field.model.name}
      variant="unique"
      className={classNames(field.model.cssClass)}
    />
  )
}
