import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import './Select.scss'
import { isClient, isMobileDevice } from '../../util'
import { Icon } from '../Icon'

/**
 * @callback FieldChangeCallback
 * @param {string} key
 * @param {string | string[] | File[]} value
 * @param {boolean} isValid
 * @param {string[]} errors
 */

/**
 * @param {Object} props
 * @param {Object} props.tracker
 * @param {Array.<string>} props.errors
 * @param {boolean} props.disabled
 * @param {Object} props.field
 * @param {Object} props.field.model
 * @param {string} props.field.model.title
 * @param {string} props.field.model.cssClass
 * @param {string} props.field.model.labelCssClass
 * @param {string} props.field.model.name
 * @param {boolean} props.field.model.required
 * @param {boolean} props.field.model.showEmptyItem
 * @param {Array.<{text: string, value: string, selected: boolean, itemId: string}>} props.field.model.items
 * @param {Object} props.field.valueField
 * @param {string} props.field.valueField.id
 * @param {FieldChangeCallback} props.onChange
 * @param {string[]} props.value
 * @param {function(string): string} props.t
 */
function Select(props) {
  const { errors, disabled, field, onChange, tracker, t, value } = props
  let { items } = field.model

  const [active, _setActive] = useState(false)
  const [optionIndex, _setOptionIndex] = useState(0)
  const selectRef = React.useRef(null)
  const listId = useMemo(() => `selectList_${field.valueField.id}`, [field.valueField.id])
  const itemIdPrefix = useMemo(() => `${field.valueField.id}_item_`, [field.valueField.id])

  // add an empty item
  if (field.model.showEmptyItem) {
    items = [{ value: '', text: '', selected: false }, ...items]
  }

  /**
   * Set a selected option index.
   * @param {number} idx
   */
  const setOptionIndex = (idx) => {
    optionIndexRef.current = idx
    _setOptionIndex(idx)
  }

  const setActive = (data) => {
    activeRef.current = data
    _setActive(data)
  }

  const optionIndexRef = React.useRef(optionIndex)
  const activeRef = React.useRef(active)

  const handleClick = useCallback((e) => {
    if (isMobileDevice()) return

    if (!(selectRef?.current?.contains(e.target) || e.target.classList.contains('select__option'))) {
      setActive(false)
    }
  }, [])

  const validateChangedValue = useCallback(
    (newValue) => {
      let valid = true
      let errorMessages = []

      if (field.model.required && !newValue) {
        valid = false
        errorMessages.push(`${field.model.title} ${t('aw_common_form_field_required')}`)
      }

      onChange(field.valueField.name, newValue, valid, errorMessages)
    },
    [field.model.required, field.model.title, field.valueField.name, onChange, t],
  )

  const handleKeyDown = useCallback(
    (e) => {
      if (!activeRef.current) {
        return
      }
      e.preventDefault()

      const optionIndex = optionIndexRef.current

      switch (e.key) {
        case 'Escape':
        case 'Enter':
        case 'Tab':
          setActive(false)
          validateChangedValue(items[optionIndex].value)
          break
        case 'Up':
        case 'ArrowUp':
          if (optionIndex > 0) {
            setOptionIndex(optionIndexRef.current - 1)
          }
          break
        case 'Down':
        case 'ArrowDown':
          if (optionIndex < items.length - 1) {
            setOptionIndex(optionIndexRef.current + 1)
          }
          break
        default:
          break
      }
    },
    [items, validateChangedValue],
  )

  const handleFocus = useCallback(
    (ev) => {
      tracker.onFocusField(field, ev.target.value)
    },
    [field, tracker],
  )

  const handleBlur = useCallback(
    (ev) => {
      tracker.onBlurField(field, ev.target.value, errors)
    },
    [field, tracker, errors],
  )

  useEffect(() => {
    if (!isClient()) {
      return
    }

    /* document events for correct behavior */
    document.addEventListener('click', handleClick)
    document.addEventListener('keydown', handleKeyDown)

    // component will unmount
    return () => {
      document.removeEventListener('click', handleClick)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClick, handleKeyDown])

  const handleDisplayChange = useCallback(() => {
    if (disabled) return

    setActive(!active)
  }, [active, disabled])

  const handleOptionClick = useCallback(
    (e) => {
      items.forEach((option, index) => {
        if (option.value.toString() === e.target.getAttribute('data-id')) {
          setOptionIndex(index)
          validateChangedValue(items[index].value)
        }
      })

      handleDisplayChange()
    },
    [items, handleDisplayChange, validateChangedValue],
  )

  const handleChange = useCallback(
    (e) => {
      items.forEach((option, index) => {
        if (option.value.toString() === e.target.value) {
          setOptionIndex(index)
        }
      })

      validateChangedValue(e.target.value)
    },
    [items, validateChangedValue],
  )

  // set a default value when component mounts
  // or clear the selected value when form is submitted
  useEffect(() => {
    if (value.length) {
      const index = items.findIndex((item) => item.value === value[0])

      if (index !== -1) {
        setOptionIndex(index)
      }
    } else {
      setOptionIndex(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const classes = classNames('selected-text', {
    'selected-text--active': active,
    'selected-text--value': items[optionIndex],
    'selected-text--error': errors && errors.length,
    'selected-text--disabled': disabled,
  })

  return (
    <div className="select">
      <label
        htmlFor={field.valueField.id}
        className={classNames('visually-hidden', field.model.labelCssClass)}
      >
        {field.model.title}
      </label>

      {isMobileDevice() ? (
        <div className="select--mobile">
          <select
            value={items[optionIndex].value}
            disabled={disabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            id={field.valueField.id}
            className={classNames(field.model.cssClass)}
            required={field.model.required}
          >
            {items.map((option, index) => (
              <option value={option.value} key={index}>
                {option.text}
              </option>
            ))}
          </select>
          <span className="label" aria-hidden="true">{field.model.title}</span>
        </div>
      ) : (
        <>
          {/**
           * On desktop render a hidden <select> that is being synchronized, so the component can be used
           * inside a <form> and value of selected item/option is available via form's GET/POST.
           */}
          <select
            className={classNames('select--hidden', field.model.cssClass)}
            value={items[optionIndex].value}
            disabled={disabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            id={field.valueField.id}
            required={field.model.required}
            hidden
          >
            {items.map((option, index) => (
              <option value={option.value} key={index}>
                {option.text}
              </option>
            ))}
          </select>
          {/**
           * Custom select input
           */}
          <button
            type="button"
            className={classes}
            onClick={handleDisplayChange}
            role="combobox"
            aria-expanded={active}
            disabled={disabled}
            ref={selectRef}
            aria-controls={listId}
            aria-activedescendant={
              active && optionIndex !== null ? `${itemIdPrefix}${optionIndex}` : undefined
            }
          >
            <span className="label">{field.model.title}</span>
            <span className="value">{items[optionIndex].text}</span>
            <Icon name="arrow" size={20} className="select__icon" />
          </button>
          {active && (
            <ul className="select__options" role="listbox" id={listId}>
              {items.map((option, index) => {
                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <li
                    role="option"
                    className={classNames('select__option', {
                      'select__option--selected': items[optionIndex].value === option.value,
                    })}
                    data-name={option.text}
                    data-id={option.value}
                    key={index}
                    id={`${itemIdPrefix}${index}`}
                    onClick={handleOptionClick}
                    aria-selected={items[optionIndex].value === option.value}
                  >
                    {option.text}
                  </li>
                )
              })}
            </ul>
          )}
        </>
      )}
      {errors && errors.length > 0 && <div className="select__error">{errors[0]}</div>}
    </div>
  )
}

export default withTranslation()(Select)
