import React from 'react'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import './RichText.scss'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Object} props.fields.Text
 */

const aw_common_richtext = (props) => {
  const id = props.rendering?.uid

  return (
    <div
      className="richtext container component"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
    >
      <RichText field={props.fields?.Text} />
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_richtext))
