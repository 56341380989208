import React from 'react'
import classNames from 'classnames'

import './Icon.scss'

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {number} props.size
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} props.className
 * @param {string} props.color
 * @param {0 | 90 | 180 | 270} props.rotate
 *
 * @returns SVGSVGElement
 */
export const Icon = ({
  name,
  size,
  width,
  height,
  className,
  color = '#000000',
  rotate = 0,
  ...rest
}) => {
  return (
    <svg
      width={size || width}
      height={size || height}
      className={classNames({
        icon: true,
        [`icon--rotate-${rotate}`]: Boolean(rotate),
        [className]: Boolean(className),
      })}
      preserveAspectRatio="none"
      focusable={false}
      aria-hidden="true"
      fill={color}
      {...rest}
    >
      <use xlinkHref={`#${name}`} />
    </svg>
  )
}
