import React, { useCallback, useEffect, useRef, Fragment } from 'react'
import classNames from 'classnames'

import './FileInput.scss'
import { withTranslation } from 'react-i18next'

/**
 * @callback FieldChangeCallback
 * @param {string} key
 * @param {string | string[] | File[]} value
 * @param {boolean} isValid
 * @param {string[]} errors
 */

/**
 *
 * @param {Object} props
 * @param {string[]} props.errors
 * @param {Object} props.tracker
 * @param {boolean} props.isValid
 * @param {Object} props.field
 * @param {Object} props.field.model
 * @param {string} props.field.model.title
 * @param {string} props.field.model.cssClass
 * @param {string} props.field.model.labelCssClass
 * @param {boolean} props.field.model.isMultiple
 * @param {boolean} props.field.model.required
 * @param {number} props.field.model.fileSizeUnit
 * @param {number} props.field.model.maxFileSize
 * @param {number} props.field.model.maxFileCount
 * @param {string} props.field.model.name
 * @param {string} props.field.model.allowedContentTypes
 * @param {Object} props.field.valueField
 * @param {string} props.field.valueField.id
 * @param {string} props.field.valueField.name
 * @param {FieldChangeCallback} props.onChange
 * @param {string | File[]} props.value
 * @param {function(string): string} props.t
 */
const FileInput = (props) => {
  const { field, errors, onChange, tracker, value, t } = props
  const fileInputRef = useRef(null)

  // clear file input value
  useEffect(() => {
    if (!value && fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }, [value])

  const handleChange = useCallback(
    (ev) => {
      // convert FileList to a plain array
      const files = Array.from(ev.target.files ?? [])

      let valid = true
      let errorMessages = []

      // empty input validation
      if (field.model.required && !files.length) {
        valid = false
        errorMessages.push(`${field.model.title} ${t('aw_common_form_field_required')}`)
      }

      // file count validation
      if (files.length > field.model.maxFileCount && field.model.maxFileCount > 0) {
        valid = false
        errorMessages.push(t('aw_common_form_files_count_exceeded'))
      }

      // file size validation
      if (files.length && field.model.maxFileSize) {
        const maxFileSize = field.model.maxFileSize * field.model.fileSizeUnit
        files.forEach((file) => {
          if (file.size > maxFileSize) {
            valid = false
            errorMessages.push(`${file.name} ${t('aw_common_form_file_too_large')}`)
          }
        })
      }

      onChange(field.valueField.name, files, valid, errorMessages)
    },
    [
      field.model.required,
      field.valueField.name,
      field.model.title,
      onChange,
      field.model.maxFileSize,
      field.model.fileSizeUnit,
      field.model.maxFileCount,
      t,
    ],
  )

  const handleFocus = useCallback(
    (ev) => {
      tracker.onFocusField(field, ev.target.value)
    },
    [field, tracker],
  )

  const handleBlur = useCallback(
    (ev) => {
      tracker.onBlurField(field, ev.target.value, errors)
    },
    [field, tracker, errors],
  )

  return (
    <div className="file-input">
      <label
        htmlFor={field.valueField.id}
        className={classNames('file-input__label', field.model.labelCssClass)}
      >
        {field.model.title}
      </label>
      <input
        type="file"
        id={field.valueField.id}
        name={field.model.name}
        className={classNames('file-input__input', field.model.cssClass)}
        multiple={field.model.isMultiple}
        aria-describedby={errors?.length > 0 ? `${field.valueField.id}_errorText` : undefined}
        accept={field.model.allowedContentTypes ? field.model.allowedContentTypes : undefined}
        required={field.model.required}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete="off"
        ref={fileInputRef}
      />
      {errors && errors.length > 0 && (
        <div id={`${field.valueField.id}_errorText`} className="file-input__error">
          {errors.map((error, idx) => (
            <Fragment key={idx}>
              {error}
              <br />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default withTranslation()(FileInput)
