import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import './SlimContent.scss'

const aw_content_slim = (props) => {
  return (
    <div className="slim_content__container">
      <Placeholder name="ph-aw-content-slim" rendering={props.rendering} />
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_content_slim))
