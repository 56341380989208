import React from 'react'
import classNames from 'classnames'

import { Icon } from '../Icon'
import './Button.scss'

/**
 * @param {Object} props
 * @param {boolean} props.disabled
 * @param {string} props.className
 * @param {Object} props.field
 * @param {Object} props.field.value
 * @param {string} props.field.value.text
 * @param {string} props.field.value.icon
 * @param {string} props.field.value.href
 * @param {string} props.svg
 * @param {"primary" | "secondary" | "tertiary" | "unique" } props.variant
 * @param {string} props.text - Used when field.value.text is empty
 */
export function Button({ disabled, field, svg, variant, className, text, ...rest }) {
  const label = field.value?.text ? field.value.text : text

  const icon = (
    <Icon
      name={field.value.icon ? field.value.icon : svg ? svg : 'arrow'}
      size={field.value.icon ? 48 : 18}
      className="btn__icon"
    />
  )

  const classes = classNames('btn', {
    'btn--icon': field.value.icon,
    [`btn--${variant}`]: !field.value.icon,
    'btn--disabled': disabled,
    [className]: Boolean(className),
  })

  if (field.value && field.value.href) {
    return (
      <a href={field.value.href} className={classes} {...rest}>
        {label}
        {icon}
      </a>
    )
  } else if (field.value) {
    return (
      <button field={field.value} className={classes} {...rest}>
        {label}
        {icon}
      </button>
    )
  }

  return null
}
