import React from 'react'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Button } from '../../common/Button'
import '../aw_common_teaserbox_text/TeaserBox.scss'
import { hasHref } from '../../util'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Object} props.fields.Subheadline
 * @param {Object} props.fields.Headline
 * @param {Object} props.fields.CtaButton
 * @param {Object} props.fields.CtaLink
 * @param {Object} props.fields.Text
 * @property {boolean} props.rendering
 */

const aw_common_teaserbox_images_left = (props) => {
  const id = props.rendering?.uid

  return (
    <div className="teaserbox container component">
      <div
        className="row align-items-center"
        data-navigation-label={props.params.InPageNavigationLabel}
        id={id}
      >
        <div className="col-12 col-md-6 col-lg-7">
          <div className="row row-cols-2 row-cols-md-2 row-cols-lg-auto mb-8 mb-md-0 small">
            <Placeholder name="ph-aw-common-teaserbox-images" rendering={props.rendering} />
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 offset-lg-1 d-flex flex-column justify-content-center">
          <Text className="mb-4 mb-md-6 h4" field={props.fields?.Subheadline} tag="p" />
          <Text field={props.fields?.Headline} tag="h2" />
          <RichText className="mt-4 mt-md-6" field={props.fields.Text} />
          {(hasHref(props.fields?.CtaButton) || hasHref(props.fields?.CtaLink)) && (
            <div className="d-flex flex-wrap justify-content-start mt-4 mt-md-6">
              {hasHref(props.fields?.CtaButton) && (
                <Button field={props.fields?.CtaButton} variant="secondary" className="mb-2 me-4" />
              )}
              {hasHref(props.fields?.CtaLink) && (
                <Button field={props.fields?.CtaLink} variant="tertiary" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_teaserbox_images_left))
