import React from 'react'
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Button } from '../../common/Button'

import './InsightsInset.scss'
import { hasHref } from '../../util'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Object} props.fields.CtaLink
 * @param {Object} props.fields.Headline
 * @param {Object} props.fields.Image
 * @param {Object} props.fields.Text
 * @param { "normal" | "wide" } props.variant
 */
const aw_common_insights_inset = (props) => {
  return (
    <>
      {props.variant === 'normal' && (
        <div className="insights-inset col-12 col-lg-4 col-md-6 mb-4">
          <div className="insights-inset--normal">
            <div className="insights-inset__img-wrapper">
              {props.fields?.Image && <Image field={props.fields.Image} loading="lazy" />}
            </div>
            <div className="insights-inset--normal__content px-8">
              <Text field={props.fields?.Headline} tag="h3" className="h2" />
              <RichText field={props.fields?.Text} tag="p" />
              {hasHref(props.fields?.CtaLink) && (
                <Button
                  field={props.fields.CtaLink}
                  variant="tertiary"
                  aria-label={`${props.t(props.fields.CtaLink.value.text)} ${props.t(
                    'aw_common_about',
                  )} ${props.fields?.Headline.value}`}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {props.variant === 'wide' && (
        <div className="insights-inset col-12 col-md-12 col-lg-6 mb-4">
          <div className="insights-inset--wide">
            <div className="container">
              <div className="row g-0">
                {props.fields?.Image && (
                  <Image field={props.fields?.Image} className="col-12 col-md-6" loading="lazy" />
                )}
                <div className="insights-inset--wide__content px-8 col-12 col-md-6">
                  <Text field={props.fields?.Headline} tag="h3" className="h2" />
                  <RichText field={props.fields?.Text} tag="p" />
                  {hasHref(props.fields?.CtaLink) && (
                    <Button
                      field={props.fields.CtaLink}
                      variant="tertiary"
                      aria-label={`${props.t(props.fields.CtaLink.value.text)} ${props.t(
                        'aw_common_about',
                      )} ${props.fields?.Headline.value}`}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_insights_inset))
