import React from 'react'
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Icon } from '../../common/Icon'

import { Button } from '../../common/Button'
import '../aw_common_teaserbox_text/TeaserBox.scss'
import { hasHref } from '../../util'
import { isClient } from '../../util'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Object} props.fields.Subheadline
 * @param {Object} props.fields.Headline
 * @param {Object} props.fields.CtaButton
 * @param {Object} props.fields.CtaLink
 * @param {Object} props.fields.Text
 * @param {Object} props.fields.Video
 * @param {String} props.fields.Video.value
 */

const aw_common_teaserbox_image_left = (props) => {
  const id = props.rendering?.uid

  const videoId = props.fields.Video?.value

  const openVideoModal = () => {
    if (!isClient()) return
    const openModalEvent = new CustomEvent('openModal', {
      detail: { type: 'videoModal', videoId },
    })
    window.dispatchEvent(openModalEvent)
  }

  return (
    <div className="teaserbox container component">
      <div
        className="row align-items-center"
        data-navigation-label={props.params.InPageNavigationLabel}
        id={id}
      >
        <div className="teaserbox__asset-wrapper col-12 col-md-6 col-lg-7 mb-8 mb-md-0 d-none d-md-block">
          <Image field={props.fields?.Image} loading="lazy" />
          {videoId && (
            <button
              onClick={openVideoModal}
              aria-label={props.t('aw_common_play_video')}
              className="teaserbox__play-button"
            >
              <Icon name="play" size={64} />
            </button>
          )}
        </div>
        <div className="col-12 col-md-6 col-lg-4 offset-lg-1 d-flex flex-column justify-content-center">
          <Text className="mb-4 mb-md-6 h4" field={props.fields?.Subheadline} tag="p" />
          <Text field={props.fields?.Headline} tag="h2" />
          <div className="teaserbox__asset-wrapper col-12 col-md-6 col-lg-7 offset-lg-1 mt-8 mt-md-0 d-sm-block d-md-none">
            <Image field={props.fields?.Image} loading="lazy" />
            {videoId && (
              <button
                onClick={openVideoModal}
                aria-label={props.t('aw_common_play_video')}
                className="teaserbox__play-button"
              >
                <Icon name="play" size={64} />
              </button>
            )}
          </div>
          <RichText className="mt-4 mt-md-6" field={props.fields?.Text} />
          {(hasHref(props.fields?.CtaButton) || hasHref(props.fields?.CtaLink)) && (
            <div className="d-flex flex-wrap justify-content-start mt-4 mt-md-6">
              {hasHref(props.fields?.CtaButton) && (
                <Button field={props.fields?.CtaButton} variant="secondary" className="mb-2 me-4" />
              )}
              {hasHref(props.fields?.CtaLink) && (
                <Button field={props.fields?.CtaLink} variant="tertiary" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_teaserbox_image_left))
