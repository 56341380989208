import React from 'react'
import { Text, Link, Image } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { Icon } from '../../common/Icon'
import './Headquarters.scss'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {Object} props.t
 * @param {Object} props.fields
 * @param {string} props.fields.Address
 * @param {string} props.fields.Plane
 * @param {string} props.fields.Car
 * @param {string} props.fields.Train
 * @param {string} props.fields.Tram
 * @param {string} props.fields.Subway
 * @param {string} props.fields.Map
 * @param {string} props.fields.MapLink
 */

const aw_common_headquarters = (props) => {
  const id = props.rendering?.uid

  return (
    <div
      className="headquarters container component"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
    >
      {props.fields ? (
        <>
          <h2 className="mb-2 mb-lg-6 headquarters-label h4">
            {props.t('aw_common_headquarters_label_top')}
          </h2>
          <div className="mb-8 mb-md-12 row justify-content-between">
            <div className="mb-6 mb-md-4 col-12 col-lg-4">
              {props.fields.Address && <Text className="h2" field={props.fields.Address} tag="p" />}
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              {props.fields.Plane?.value && (
                <div className="headquarters-direction">
                  <div>
                    <Icon name="plane-line" size={24} />
                    <h3 className="headquarters-direction__name h4">
                      {props.t('aw_common_headquarters_label_byplane')}
                    </h3>
                  </div>
                  <Text
                    className="headquarters-direction__desc body"
                    field={props.fields.Plane}
                    tag="p"
                  />
                </div>
              )}
              {props.fields.Car?.value && (
                <div className="headquarters-direction">
                  <div>
                    <Icon name="car-fill" size={24} />
                    <h3 className="headquarters-direction__name h4">
                      {props.t('aw_common_headquarters_label_bycar')}
                    </h3>
                  </div>
                  <Text
                    className="headquarters-direction__desc body"
                    field={props.fields.Car}
                    tag="p"
                  />
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              {props.fields.Train?.value && (
                <div className="headquarters-direction">
                  <div>
                    <Icon name="train-fill" size={24} />
                    <h3 className="headquarters-direction__name h4">
                      {props.t('aw_common_headquarters_label_bytrain')}
                    </h3>
                  </div>
                  <Text
                    className="headquarters-direction__desc body"
                    field={props.fields.Train}
                    tag="p"
                  />
                </div>
              )}
              {props.fields.Tram?.value && (
                <div className="headquarters-direction">
                  <div>
                    <Icon name="tram-fill" size={24} />
                    <h3 className="headquarters-direction__name h4">
                      {props.t('aw_common_headquarters_label_bytram')}
                    </h3>
                  </div>
                  <Text
                    className="headquarters-direction__desc body"
                    field={props.fields.Tram}
                    tag="p"
                  />
                </div>
              )}
              {props.fields.Subway?.value && (
                <div className="headquarters-direction">
                  <div>
                    <Icon name="subway-fill" size={24} />
                    <h3 className="headquarters-direction__name h4">
                      {props.t('aw_common_headquarters_label_bysubway')}
                    </h3>
                  </div>
                  <Text
                    className="headquarters-direction__desc body"
                    field={props.fields.Subway}
                    tag="p"
                  />
                </div>
              )}
            </div>
          </div>
          {props.fields?.Map?.value && props.fields.MapLink?.value?.href && (
            <div className="row">
              <div className="headquarters-map">
                <Link field={props.fields.MapLink}>
                  <Image
                    field={props.fields.Map}
                    className="headquarters-map__image"
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
          )}
        </>
      ) : (
        props.sitecoreContext?.pageEditing && <p>Missing datasource</p>
      )}
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_headquarters))
