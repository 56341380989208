import React, { useState, useCallback } from 'react'
import {
  createDefaultFieldFactory,
  FieldTypes,
  Form as JssForm,
} from '@sitecore-jss/sitecore-jss-react-forms'
import { withTranslation } from 'react-i18next'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { TextField } from '../TextField'
import { TextArea } from '../TextArea'
import { SubmitButton } from '../SubmitButton'
import { SpinnerOverlay } from '../Spinner'
import { FileInput } from '../FileInput'
import { Select } from '../Select'
import { isClient } from '../../util'

const defaultFieldFactory = createDefaultFieldFactory()

const ErrorComponent = () => {
  return <div></div>
}

defaultFieldFactory.setComponent(FieldTypes.SingleLineText, (props) => (
  <TextField {...props} type="text" />
))
defaultFieldFactory.setComponent(FieldTypes.Email, (props) => <TextField {...props} type="email" />)
defaultFieldFactory.setComponent(FieldTypes.Telephone, (props) => (
  <TextField {...props} type="tel" />
))
defaultFieldFactory.setComponent(FieldTypes.MultipleLineText, (props) => <TextArea {...props} />)
defaultFieldFactory.setComponent(FieldTypes.Button, (props) => <SubmitButton {...props} />)
defaultFieldFactory.setComponent(FieldTypes.FileUpload, (props) => <FileInput {...props} />)
defaultFieldFactory.setComponent(FieldTypes.DropdownList, (props) => <Select {...props} />)

/**
 * @callback onRedirect
 * @param {string} url
 */

/**
 * @param {Object} props
 * @param {Object} props.form
 * @param {string} props.form.htmlPrefix
 * @param {string} props.sitecoreApiHost
 * @param {string} props.sitecoreApiKey
 * @param {onRedirect} props.onRedirect
 */
export function Form({ captureForm, ...rest }) {
  const [loading, setLoading] = useState(false)

  // customize the behavior of the default FormFetcher
  // add a custom dataLayer form tracking
  // https://jss.sitecore.com/docs/techniques/forms#customizing-form-fetcher
  const formFetcher = useCallback((formData, endpoint) => {
    setLoading(true)

    return fetch(endpoint, {
      body: formData.toMultipartFormData(),
      method: 'post',
      // IMPORTANT: Sitecore forms relies on cookies for some state management, so credentials must be included.
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          window.dataLayer.push({
            event: 'form',
            formType: 'contact',
          })
          if (captureForm && isClient) {
            const htmlPrefix = { ...rest }.form.htmlPrefix
            const form = document.querySelector(`form[action*="${htmlPrefix}"]`)

            // Hydrated from external Dynamics script.
            window.MsCrmMkt && window.MsCrmMkt.MsCrmFormLoader.sendFormCaptureToCrm(form)
          }
        }
        return res.json()
      })
      .catch(() => {
        return {
          success: false,
          errors: 'Something went wrong. Error was thrown when submit form',
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <SpinnerOverlay loading={loading}>
      <JssForm
        errorComponent={ErrorComponent}
        fieldFactory={defaultFieldFactory}
        formFetcher={formFetcher}
        {...rest}
      />
    </SpinnerOverlay>
  )
}

export default withSitecoreContext()(withTranslation()(Form))
