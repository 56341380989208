import React from 'react'
import { Image, Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Icon } from '../../common/Icon'

import { Button } from '../../common/Button'
import { hasHref } from '../../util'

import { isClient } from '../../util'
import './HeroBanner.scss'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {Object} props.fields
 * @param {Object} props.fields.CtaButton
 * @param {Object} props.fields.CtaButton.value
 * @param {Object} props.fields.CtaLink
 * @param {Object} props.fields.CtaLink.value
 * @param {Object} props.fields.Headline
 * @param {string} props.fields.Headline.value
 * @param {Object} props.fields.Image
 * @param {Object} props.fields.Image.value
 * @param {Object} props.fields.Perex
 * @param {string} props.fields.Perex.value
 * @param {Object} props.fields.Video
 * @param {String} props.fields.Video.value
 */
const aw_common_hero_banner = (props) => {
  const id = props.rendering?.uid

  const videoId = props.fields.Video?.value

  const openVideoModal = () => {
    if (!isClient()) return
    const openModalEvent = new CustomEvent('openModal', {
      detail: { type: 'videoModal', videoId },
    })
    window.dispatchEvent(openModalEvent)
  }

  return (
    <div
      className="hero-banner component"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="hero-banner__content col-12 col-sm-12 col-md-6 col-lg-6">
            {props.fields?.Headline && <Text field={props.fields?.Headline} tag="h1" />}
            {props.fields?.Perex && <Text field={props.fields?.Perex} tag="p" />}
            {hasHref(props.fields?.CtaButton) && (
              <Button field={props.fields?.CtaButton} variant="primary" />
            )}
            {hasHref(props.fields?.CtaLink) && (
              <Button field={props.fields?.CtaLink} variant="tertiary" />
            )}
          </div>
          {props.fields?.Image && (
            <div className="hero-banner__asset-wrapper col-12 col-sm-12 col-md-6 col-lg-6">
              <Image className="hero-banner__img" field={props.fields?.Image} />
              {videoId && (
                <button
                  onClick={openVideoModal}
                  aria-label={props.t('aw_common_play_video')}
                  className="hero-banner__play-button"
                >
                  <Icon name="play" size={64} />
                </button>
              )}
            </div>
          )}
        </div>
        <div className="hero-banner__rect mx-3"></div>
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_hero_banner))
