import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Button } from '../../common/Button'

import './InsightsNormal.scss'

/**
 * @param {Object} props
 * @param {boolean} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {string} props.params.InPageNavigationVisible
 * @param {Object} props.fields
 * @param {Object} props.fields.Headline
 * @param {Object} props.fields.CtaButton
 */
const aw_common_insights_normal = (props) => {
  const id = props.rendering?.uid
  return (
    <div
      data-navigation-label={props.params.InPageNavigationLabel}
      className="insights-normal container component"
      id={id}
    >
      {props.fields?.Headline && <Text field={props.fields?.Headline} tag="h2" />}
      <div className="row">
        <Placeholder
          name="ph-aw-common-insights-insets"
          rendering={props.rendering}
          variant="normal"
        />
      </div>
      {props.fields?.CtaButton && props.fields?.CtaButton.href && (
        <Button field={props.fields?.CtaButton} variant="secondary" />
      )}
    </div>
  )
}
export default withSitecoreContext()(withTranslation()(aw_common_insights_normal))
