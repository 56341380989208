import React, { useState, useEffect } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import { isClient } from '../../util'
import './InpageNav.scss'

const InpageNavigation = (props) => {
  const { t } = props
  const [navLinks, setNavLinks] = useState([])

  useEffect(() => {
    if (!isClient()) {
      return
    }

    const domNavLinks = Array.from(document.querySelectorAll('[data-navigation-label]'))
    const elementsWithId = []

    domNavLinks.forEach((element) => {
      if (element.id) {
        elementsWithId.push({ id: element.id, label: element.dataset?.navigationLabel })
        return
      }

      const ids = element.querySelectorAll('[id]')

      ids.forEach((e) => {
        elementsWithId.push({ id: e.id, label: element.dataset?.navigationLabel })
      })
    })

    setNavLinks(elementsWithId)
  }, [])

  if (navLinks.length === 0) {
    if (props.sitecoreContext?.pageEditing ?? false) {
      return (
        <div className="container inpage-nav component">
          <ul className="inpage-nav-list">
            <li className="inpage-nav-list__link">
              InPage navigation. Content cannot be generated in edit mode.
            </li>
          </ul>
        </div>
      )
    }

    return <></>
  }

  return (
    <div className="container inpage-nav component">
      <nav aria-label={t('aw_common_inpage_navigation')}>
        <ul className="inpage-nav-list">
          {navLinks.map((link) => (
            <li className="inpage-nav-list__link" key={link.id}>
              <a className="h4 btn btn--tertiary" href={'#' + link.id}>
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

const aw_common_inpage_navigation = (props) => <InpageNavigation {...props} />

export default withSitecoreContext()(withTranslation()(aw_common_inpage_navigation))
