import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import { Form } from '../../common/Form'
import { sitecoreApiHost, sitecoreApiKey } from '../../temp/config'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationVisible
 * @param {"1"} props.params.IsDynamicsForm If the form is to be submitted to Dynamics, the value is "1". If not, the property is not defined.
 * @param {Object} props.fields
 * @param {Object} props.history
 */

const aw_common_events_registration_form = (props) => {
  const id = props.rendering?.uid
  return (
    <div
      className="form form-register container component"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
    >
      <Form
        form={props.fields}
        sitecoreApiHost={sitecoreApiHost}
        sitecoreApiKey={sitecoreApiKey}
        captureForm={Boolean(props.params.IsDynamicsForm)}
        // onRedirect={(url) => props.history.push(url)}
      />
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_events_registration_form))
