import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

import './Navigation.scss'
import { Navigation } from './Navigation'

/**
 * @typedef {Object} Item
 * @property {Array<Item>} childs
 * @property {string} displayName
 * @property {Object} fields
 * @property {string} id
 * @property {string} name
 * @property {string} template
 *
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Array<Object>} props.fields.items
 * @param {function(string): string} props.t
 */
const aw_navigation_main = (props) => {
  return <Navigation {...props} />
}

export default withSitecoreContext()(withTranslation()(aw_navigation_main))
