import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'

/**
 * @param {Object} props
 * @param {Object} props.params
 * @param {string} props.params.InPageNavigationLabel
 * @param {Object} props.fields
 * @param {Object} props.fields.Headline
 * @param {Object} props.fields.Subheadline
 * @property {boolean} props.rendering
 */

const aw_common_faq = (props) => {
  const id = props.rendering?.uid

  return (
    <div
      className="faq container component"
      data-navigation-label={props.params.InPageNavigationLabel}
      id={id}
    >
      {props.fields?.Subheadline && (
        <Text className="h4 mb-6" field={props.fields?.Subheadline} tag="p" />
      )}
      <div className="row">
        {props.fields?.Headline && (
          <div className="col-12 col-md-6 mb-6">
            <Text field={props.fields?.Headline} tag="h2" />
          </div>
        )}
        <div className="col-12 col-md-6">
          <Placeholder name="ph-aw-common-faq-insets" rendering={props.rendering} />
        </div>
      </div>
    </div>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_faq))
