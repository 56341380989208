import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Icon } from '../Icon'

import './LanguageSwitcher.scss'
import { isClient } from '../../util'

/**
 * @typedef {Object} Language
 * @property {string} label
 * @property {string} url
 * @property {string} value
 * @property {string} title
 *
 */

/**
 * @type {Language}
 */
const FALLBACK_LANGUAGE = {
  label: 'EN',
  url: '/',
  value: 'en',
  title: 'English',
}

/**
 * @param {Object} props
 * @param {Array<Language>} props.languages
 * @param {string} props.currentLanguageCode
 * @param {"dropdown" | "inline"} props.type
 * @param {function(string): string} props.t
 */
const LanguageSwitcher = ({ languages, currentLanguageCode, t, type = 'dropdown' }) => {
  const [visible, setVisible] = useState(false)
  const switcherRef = useRef(null)
  const buttonRef = useRef(null)
  /**
   * @type {HTMLAnchorElement[]}
   */
  const languageLinks = Array.from(switcherRef?.current?.querySelectorAll('.dropdown-item') ?? [])
  const activeLinkIndex = useRef(null)
  const currLanguage = useMemo(
    () =>
      languages.find((l) => l.value.toLowerCase() === currentLanguageCode.toLowerCase()) ??
      FALLBACK_LANGUAGE,
    [languages, currentLanguageCode],
  )

  const handleKeyDown = useCallback(
    (ev) => {
      if (!languageLinks.length) return

      switch (ev.key) {
        case 'Escape':
          setVisible(false)
          buttonRef.current && buttonRef.current.focus()
          break

        case 'ArrowDown':
          ev.preventDefault()

          if (activeLinkIndex.current === null) {
            activeLinkIndex.current = 0
          } else if (activeLinkIndex.current < languageLinks.length - 1) {
            activeLinkIndex.current++
          }

          languageLinks[activeLinkIndex.current].focus()
          break

        case 'ArrowUp':
          ev.preventDefault()

          if (activeLinkIndex.current === null) {
            activeLinkIndex.current = languageLinks.length - 1
          } else if (activeLinkIndex.current > 0) {
            activeLinkIndex.current--
          }

          languageLinks[activeLinkIndex.current].focus()
          break

        case 'Tab':
          if (activeLinkIndex.current === languageLinks.length - 1) {
            setVisible(false)
          }
          break

        default:
          break
      }
    },
    [languageLinks],
  )

  const handleClickOutside = useCallback((ev) => {
    if (!switcherRef?.current?.contains(ev.target)) {
      setVisible(false)
    }
  }, [])

  useEffect(() => {
    if (!isClient() || !visible || type === 'inline') return

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('click', handleClickOutside)
    }
  }, [visible, type, handleKeyDown, handleClickOutside])

  // when dropdown menu is closed => reset the current index
  useEffect(() => {
    if (!visible) {
      activeLinkIndex.current = null
    }
  }, [visible])

  if (type === 'dropdown') {
    return (
      <div className="language-switcher language-switcher--dropdown ms-3" ref={switcherRef}>
        <button
          className={classNames('btn language-switcher__toggle', {
            'language-switcher__toggle--open': visible,
          })}
          type="button"
          id="dropdownLanguage"
          aria-expanded={visible}
          aria-label={t('aw_navigation_change_language')}
          onClick={() => setVisible(!visible)}
          ref={buttonRef}
        >
          <span className="me-1">{currLanguage.label}</span>
          <Icon name="arrow" size={18} rotate={90} />
        </button>
        <ul
          className={classNames('dropdown-menu', { 'show': visible })}
          aria-labelledby="dropdownLanguage"
        >
          {languages
            .filter((l) => l.value !== currentLanguageCode)
            .map(({ label, url, title }, idx) => (
              <li key={idx}>
                <a
                  className="dropdown-item"
                  href={url}
                  onFocus={() => (activeLinkIndex.current = idx)}
                  aria-label={title}
                  title={title}
                >
                  {label}
                </a>
              </li>
            ))}
        </ul>
      </div>
    )
  }

  if (type === 'inline') {
    return (
      <div className="language-switcher language-switcher--inline">
        <ul className="language-switcher__list" aria-label={t('aw_navigation_change_language')}>
          {languages.map(({ label, url, title, value }, idx) => (
            <li key={idx}>
              <a
                href={url}
                aria-label={title}
                className={classNames('language-switcher__language', {
                  'language-switcher__language--active':
                    value.toLowerCase() === currLanguage.value.toLowerCase(),
                })}
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default withTranslation()(LanguageSwitcher)
