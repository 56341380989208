import React, { useState } from 'react'
import classNames from 'classnames'
import { Icon } from '../../common/Icon'
import { Collapse } from '../../common/Collapse'

/**
 * @param {Object} props
 * @param {string | number} props.id
 * @param {string} props.label
 */
export const FooterCollapse = ({ id, label, children }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <button
        type="button"
        className="footer__collapse-btn h4"
        onClick={() => setOpen((prev) => !prev)}
        id={`footerCollapseBtn_${id}`}
        aria-expanded={isOpen}
        aria-controls={`footerCollapseContent_${id}`}
      >
        <span className="me-2">{label}</span>
        <Icon
          name="arrow"
          rotate={isOpen ? 270 : 90}
          size={24}
          className={classNames({
            'footer__collapse-icon': true,
            'footer__collapse-icon--open': isOpen,
          })}
        />
      </button>
      <Collapse
        isOpen={isOpen}
        id={`footerCollapseContent_${id}`}
        aria-labelledby={`footerCollapseBtn_${id}`}
      >
        <div className="footer__collapse-inner">{children}</div>
      </Collapse>
    </>
  )
}
