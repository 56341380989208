import React from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { hasHref } from '../../util'

import './PartnersInset.scss'
import { Link } from '../../common/Link/Link'

/**
 * @param {Object} props
 * @param {Object} props.fields
 * @param {Object} props.fields.Image
 * @param {Object} props.fields.Link
 * @param {Object} props.fields.Title
 */
const aw_common_partners_inset = (props) => {
  const isValidLink = hasHref(props.fields?.Link)

  return (
    <>
      <li className="partner col-xl-2 col-md-3 col-6 p-2">
        <div
          className={classNames('partner-inset', {
            'partner-inset--clickable': isValidLink,
          })}
        >
          {props.fields?.Image && (
            <Image
              className="partner-inset__img"
              field={props.fields?.Image}
              aria-hidden="true"
              loading="lazy"
            />
          )}
          {props.fields?.Link && (
            <Link field={props.fields?.Link} variant="body" disabled={!isValidLink} />
          )}
        </div>
      </li>
    </>
  )
}

export default withSitecoreContext()(withTranslation()(aw_common_partners_inset))
